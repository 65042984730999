// 常量
import * as constants from 'constants'

import { info } from './show'

/**
 * 请先登录
 * @param {Function} callback 自定义模式的回调。
 * @param {Number} mode 模式。0 跳转页面; 1 自定义模式。
 */
export function pleaseLogin({
  callback = () => {},
  mode = 0,
} = {}) {
  switch (mode) {
    case 0:
      info({
        txt: constants.tips.user.login.please,
        timeoutCallback: () => {
          window.location.href = constants.url[process.env.NODE_ENV === 'production' ? 'prod' : 'dev'].login
        },
      })
      break
    case 1:
      info({
        txt: constants.tips.user.login.please,
        timeout: callback,
      })
      break
    default:
      break
  }
}
