// jQuery
import $ from 'jquery'

import {
  ScrollLoad,
} from './scrollLoad'

/**
 * 列表页加载更多
 */
export class LoadMore extends ScrollLoad {
  constructor(options = {}) {
    let {
      createNode = () => {},
    } = options
    super(options)
    this.createNode = createNode
  }

  getFragment(options = {}) {
    let {
      ret,
      target,
    } = options
    let fragment = document.createDocumentFragment()

    // 初始化计数
    if (ret.data && ret.data[0]) {
      let node = this.createNode(ret.data[0]) // 测试节点
      let name = node.className ? `.${node.className}` : node.nodeName
      this.amt = $(target).children(name.toLowerCase()).length
    }

    for (let o of ret.data) {
      this.amt++
      fragment.appendChild(this.createNode(o))
    }

    return fragment
  }
}
