/**
 * url 相关
 */
export const url = {
  // 开发环境
  dev: {
    ig: {
      mb: './ig_mb.html?link=', // 手机百度越狱包
      nonMb: './ig_nonmb.html?link=', // 非手机百度越狱包
    }, // 安装指导
    login: './login.html',
    user: {
      index: './user_index.html', // 首页
      logout: `${SERVER_URL}${prefix}account/logout`, // 登出
    }, // 用户中心
  },
  // 生产环境
  prod: {
    ig: {
      mb: 'guide/ig_mb.shtml?link=', // 手机百度越狱包
      nonMb: 'guide/ig_nonmb.shtml?link=', // 非手机百度越狱包
    }, // 安装指导
    login: `${prefix}member/login`,
    user: {
      index: `${prefix}account/center`, // 首页
      logout: `${prefix}account/logout`, // 登出
    }, // 用户中心
  },
  // svg
  svg: {
    nameSpace: 'http://www.w3.org/2000/svg', // 命名空间
  }
}
