// 常量
import * as constants from 'constants'

import {
  log,
} from 'utils/log'

/**
 * 函数节流。当重复调用函数的时候，至少每隔 n 毫秒调用一次该函数。
 * @param {Function} fn 方法。 
 * @param {Number} mode 节流模式。0 计时器; 1  requestAnimationFrame，fallback 计时器模式。
 * @param {Number} slice 时间片。单位 ms。
 * @return {Function} 成功 节流方法; 失败 空方法。
 * BUG: 手机百度、UC等浏览器，若一直在滚动，
 */
export function throttle(options = {}) {
  if (!(argsInit(options) && argsValidate(options))) {
    return () => {}
  }

  return start(options)
}

/**
 * 参数初始化
 */
function argsInit(options) {
  let {
    mode = 1,
      slice = 250,
  } = options

  options.mode = mode
  options.slice = slice

  return true
}

/**
 * 参数校验
 */
function argsValidate(options = {}) {
  let {
    fn,
    mode,
    slice,
  } = options

  if (typeof fn !== 'function') {
    return error()
  }

  if (![0, 1].includes(mode)) {
    return error()
  }

  if (typeof slice !== 'number') {
    return error()
  }

  return true

  function error() {
    log.e(constants.tips.console.ivArgs)
    return false
  }
}

function start(options = {}) {
  let {
    fn,
    mode,
    slice,
  } = options

  let flag = true // 允许执行标识
  let timer = null // 计时器(计时器模式)

  if (!(window.requestAnimationFrame || window.webkitRequestAnimationFrame)) {
    // fallback 到计时器模式
    mode = 0
  }

  /**
   * 计时器模式
   */
  function method0() {
    if (!flag) {
      return
    }

    flag = false

    // 计时器模式
    timer = setTimeout(() => {
      fn.apply(this, arguments)
      flag = true
      clearTimeout(timer)
    }, slice)
  }

  /**
   * requestAnimationFrame 模式
   */
  function method1() {
    let rqFrame = window.requestAnimationFrame || window.webkitRequestAnimationFrame
    rqFrame(timeStamp => {
      flag = true
      fn.apply(this, arguments)
    })
  }

  return mode === 0 ? method0 : method1
}