// Viewerjs
import Viewer from 'viewerjs'
// 样式
export * from 'viewerjs/dist/viewer.min.css'
// 常量
import * as constants from 'constants'
// 工具方法
import * as utils from 'utils'

/**
 * 查看大图。
 * @param {Object} wrapper img 标签的父元素
 * @return {Object | Boolean} 成功 Viewer 对象; 失败 false。
 */
export function init(options = {}) {
  options = Object.assign({}, options)

  if (!(initArgs(options) && ivArgs(options))) {
    return false
  }

  return start(options)
}

/**
 * 参数初始化
 */
function initArgs(options = {}) {
  if (typeof options.wrapper === 'string') {
    let o = document.querySelector(options.wrapper)

    if (!o) {
      return error()
    }

    options.wrapper = o
  }

  return true

  function error() {
    utils.log.e(constants.tips.console.domElNotFound)
    return false
  }
}

/**
 * 参数校验
 */
function ivArgs(options = {}) {
  let {
    wrapper,
  } = options

  if (!(wrapper instanceof HTMLElement)) {
    return error()
  }

  return true

  function error() {
    utils.log.e(constants.tips.console.ivArgs)
    return false
  }
}

function start(options) {
  let {
    wrapper,
  } = options

  return new Viewer(wrapper, {
    url: constants.propertyName.viewer.hdUrl,
  })
}