import { getScaleRatio } from './getScaleRatio'
import { pxScale } from './pxScale'
import { pxToRem } from './pxToRem'
import { remToPx } from './remToPx'

export const scale = {
  getScaleRatio,
  pxScale,
  pxToRem,
  remToPx
}
