import { ajax, Result } from 'js/api/index'

/**
 * 登陆。该接口返回的会员信息无效。
 * @param {String} userName 用户名
 * @param {String} password 密码
 * @param {String} siteId 站点ID
 * @param {String} loginType
 */
export async function login ({ userName = '', password = '', siteId = '124', loginType = '2' } = {}) {
  let ret = await ajax.post({
    url: 'api/member/dologin',
    data: {
      username: userName,
      password,
      siteid: siteId,
      loginType,
    }
  })

  return new Result({
    data: ret.data,
    msg: ret._Message,
    status: ret._Status,
  })
}

/**
 * 查询登录状态登陆
 */
export async function isLogin () {
  let ret = await ajax.post({
    url: 'api/app/sdk_userinfo',
    data: {
      columns: 'balance,gender,keepsigninday,logofile,mobile,nickname,signature,username',
    }
  })

  return new Result({
    data: ret.data,
    msg: ret._Message,
    status: ret._Status,
  })
}
