import { log } from 'js/utils/log/index'

/**
 * 业务模块参数校验
 */

 /**
  * 实例
  * @param {Object} args 参数对象。
  * @param {String} from 来源。
  * @param {Object | String} name DOM 元素或 css 选择器。
  * @param {Array | String} instance window对象下的实例名
  * @param {Boolean} optional args[name] 是否为可选参数。
  * @return {Boolean} 成功 true; 失败 false。
  */
export function instance (options = {}) {
  let {
    args,
    from,
    name,
    instance = ['HTMLElement'],
    optional = false,
  } = options

  if (optional && typeof args[name] === 'undefined') {
    // 参数不存在且该参数可选
    return true
  }

  if (typeof instance === 'string') {
    instance = [instance]
  }

  if (typeof args !== 'object' || typeof from !== 'string' || typeof name !== 'string' || !(instance instanceof Array)) {
    log.e(`${from}[argsValidate] invalid argument.`)
    return false
  }

  let flag = false
  for (let i of instance) {
    if (typeof i !== 'string') {
      log.e(`${from}[argsValidate] invalid argument instance.`)
      return false
    }

    if (args[name] instanceof window[i]) {
      flag = true
    }
  }

  if (!flag) {
    log.e(`${from}[argsValidate] ${name} isn't an instance of ${instance}.`)
    return false
  }

  return true
}