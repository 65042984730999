// 基本方法
import { error, info, show, success, warning } from './show'
// 公共提示封装
import * as common from './common'

export const message = {
  common,
  error,
  info,
  show,
  success,
  warning,
}
