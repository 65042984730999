/**
 * html属性名
 */
export const propertyName = {
  article: {
    catalogAilas: 'data-catalogalias', // 目录别名
  }, // 文章
  blkNavTag: {
    aTagUrl: 'data-href', // 该属性的值用于填充 a 链接。
  },
  cmmt: {
    id: 'data-cmmtid', // 评论 ID 属性名
  },
  download: {
    url: {
      apk: 'data-apk-href',
      ipa: 'data-ipa-href',
    }, // 该属性用于初始化游戏主页下载按钮跳转链接。
  },
  game: {
    id: 'data-gameid', // 游戏ID
    score: {
      pip: 'data-score', // 游戏评分按钮分值属性名
    },
  },
  gift: {
    id: 'data-giftid', // 礼包 ID
    orderType: 'data-ordertype', // 排序类型(人气、最新、最热)
    popType: 'data-poptype', // 人气榜排序类型(领取数)
    type: 'data-gifttype', // 礼包榜类型
  }, // 礼包
  load: {
    btn: {
      jump: 'data-href', // 跳转链接
    }, // 按钮
  }, // 加载
  qrCode: {
    txt: 'data-href', // 该属性用于生成二维码 txt。
  },
  rank: {
    code: 'data-rankcode', // 排行榜自定义编码
    type: 'data-ranktype', // 排行榜类型
  },
  search: {
    keywords: 'data-query', // 关键字
  }, // 搜索
  viewer: {
    hdUrl: 'data-original', // 该属性用于生成大图。
  },
}
