import { log } from 'js/utils/log/index'

/**
 * 业务模块参数初始化
 */

/**
 * 表单 name 选择器元素初始化
 * @param {Object} args 参数对象。
 * @param {String} from 来源。
 * @param {Object} form 表单对象。
 * @param {Object | String} name DOM 元素或 css 选择器。
 * @return {Boolean} 成功 true; 失败 false。
 */
export function name (options = {}) {
  let {
    args,
    form,
    from = '',
    name,
  } = options

  if (typeof args !== 'object' || !(form instanceof HTMLElement) || typeof from !== 'string' || typeof name !== 'string') {
    log.e(`${from}[argsInit] invalid argument.`)
    return false
  }

  if (typeof args[name] === 'string') {
    let obj = form[args[name]]

    if (!obj) {
      log.e(`${from}[argsInit] DOM element ${name} not found.`)
      return false
    }

    args[name] = obj
  }

  return true
}

/**
 * 表单 name 选择器元素初始化
 * @param {Object} args 参数对象。
 * @param {String} from 来源。
 * @param {String} method DOM 查找方法。
 * @param {Object | String} name DOM 元素或 css 选择器。
 * @param {Object | Undefined} [parent] 父节点。
 * @return {Boolean} 成功 true; 失败 false。
 */
export function selector (options = {}) {
  let {
    args,
    from = '',
    method = 'querySelector',
    name,
    parent,
  } = options

  if (typeof args !== 'object' || typeof from !== 'string' || typeof name !== 'string' || (parent && !(parent instanceof HTMLElement))) {
    log.e(`${from}[argsInit] invalid argument.`)
    return false
  }

  if (typeof args[name] === 'string') {
    let obj = parent instanceof HTMLElement ? parent[method](args[name]) : document[method](args[name])

    if ((method === 'querySelector' && !obj) || (method === 'querySelectorAll' && obj.length === 0)) {
      log.e(`${from}[argsInit] DOM element ${name} not found.`)
      return false
    }

    args[name] = obj
  }

  return true
}