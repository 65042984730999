// 常量
import * as constants from 'constants'
import { dataBus } from 'js/common/dataBus/index'

/**
 * 获取下载按钮初始化所需参数。包括浏览器类型、平台类型
 */
export function getArgs () {
  let browser = dataBus.get({
    key: 'browser'
  })
  let brow
  if (browser.mobileBaiDu) {
    brow = constants.ua.browser.mobileBaiDu
  } else if (browser.wechatBrowser) {
    brow = constants.ua.browser.wechatBrowser
  }

  let platform = browser.ios ? constants.ua.platform.ios : constants.ua.platform.android

  return {
    browser: brow,
    platform,
  }
}
