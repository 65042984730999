// 常量
import * as constants from 'constants'
// 工具方法
import * as utils from 'utils'

export function init() {
  sticky()

  if (document.readyState === 'complete') {
    // 所有资源加载完毕
    return
  }

  window.addEventListener('load', handler, false)

  function handler () {
    sticky()
    window.removeEventListener('load', handler)
  }
}

function sticky () {
  let bd = document.querySelector('body')
  let el = document.querySelector('.yc-fter0')
  if(!el) {
    utils.log.e(constants.tips.console.domElNotFound)
    return false
  }

  let ht = Number.parseInt(window.getComputedStyle(bd, null).getPropertyValue('height'))
  let elHt = Number.parseInt(window.getComputedStyle(el, null).getPropertyValue('height'))

  if(ht > window.screen.height) {
    // 内容超过一屏
    return
  }

  if (el.classList) {
    const space = 80 // footer 与内容的间距

    el.classList.add('sticky')
    bd.style.paddingBottom = `${elHt + space}px`
  }
}
