import { ajax, Result } from 'js/api/index'

/**
 * 获取短信验证码
 * @param {String} phoneNum 手机号码
 * @param {String} from 请求来源。 1 android; 2 HTML5; 3. IOS; 4. PC
 * @param {String} type 请求类型。 1 注册验证码; 2 修改密码; 3 修改个人信息
 */
export async function sms ({ from = '2', imgCode = '', phoneNum = '', type = '1'} = {}) {
  let ret =  await ajax.post({
    url: 'api/app/sdk_smscode',
    data: {
      authcode: imgCode,
      from,
      mobile: phoneNum,
      smstemp: type
    }
  })

  return new Result({
    msg: ret._Message,
    status: ret._Status,
  })
}
