/**
 * html 属性值
 */
export const propertyValue = {
  /**
   * 下载包
   */
  package: {
    breakJail: '1', // 越狱包。
    notBreakJail: '0', // 官方包
  },
}
