// jQuery
import $ from 'jQuery'

import { dataBus } from 'js/common/dataBus'

/**
 * 广告广播初始化
 * @param {Number} averageSec 单个元素秒数
 */
export function broadcastInit(options = {}) {
  const {
    averageSec = 16
  } = options

  let broadcast = $('.yui-cads-broadcast').eq(0)
  let box = broadcast.children('.txt-box').eq(0)
  let aTags = box.children()

  removeChilds()
  fstRoundEnd()

  box.css({
    'animation-duration': `${box.children().length * averageSec}s`
  })

  /**
   * 移除其它平台节点
   */
  function removeChilds() {
    let cnt = aTags.length
    let browser = dataBus.get({
      key: 'browser'
    })

    for (let a of aTags) {
      if ((browser.android && a.getAttribute('data-client') === 'ios') || (browser.ios && a.getAttribute('data-client') === 'android')) {
        $(a).remove()
        cnt--
      }
    }

    cnt === 0 ? broadcast.remove() : broadcast.show()
  }

  /**
   * 第一轮播放结束，滚动条离开初始位置。
   */
  function fstRoundEnd () {
    box.on('webkitAnimationIteration animationiteration', addPadding)

    function addPadding () {
      box.css({
        'paddingLeft': '100%'
      })
      box.off('webkitAnimationIteration', addPadding).off('animationiteration', addPadding)
    }
  }
}