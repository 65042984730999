// 常量
import * as constants from 'constants'
// 工具方法
import * as utils from 'utils'

const txtBak = new Map() // 用于保存原始文本

function change ({
  el,
  txt = '',
}) {
  if (!txtBak.has(el)) {
    txtBak.set(el, el.innerText)
  }

  el.innerText = txt
}

/**
 * 加载完毕文本
 */
export function complete({ el = '.yc-loadbtn0', txt = constants.tips.load.btn.complete } = {}) {
  el = getElement(el)
  if (!el) {
    return false
  }

  change({
    el,
    txt,
  })
}

/**
 * 失败文本
 */
export function fail({ el = '.yc-loadbtn0', txt = constants.tips.load.btn.fail } = {}) {
  el = getElement(el)
  if (!el) {
    return false
  }

  change({
    el,
    txt,
  })
}

/**
 * 获取按钮元素
 */
function getElement(el) {
  if (typeof el === 'string') {
    el = document.querySelector(el)

    if (!el) {
      utils.log.e(constants.tips.console.domElNotFound)
      return false
    }
  }

  if (!(el instanceof HTMLElement)) {
    utils.log.e(constants.tips.console.ivArgs)
    return false
  }

  return el
}

/**
 * 跳转
 */
export function jump({ el = '.yc-loadbtn0' } = {}) {
  el = getElement(el)
  if (!el) {
    return false
  }

  let link
  if (!(link = el.getAttribute(constants.propertyName.load.btn.jump))) {
    // 未指定跳转链接
    link = process.env.NODE_ENV === 'production' ? '/' : './index.html'
  }

  el.addEventListener('click', () => window.location.href = link, false)
}

/**
 * 正在加载文本
 */
export function loading({ el = '.yc-loadbtn0', txt = constants.tips.load.btn.loading } = {}) {
  el = getElement(el)
  if (!el) {
    return false
  }

  change({
    el,
    txt,
  })
}

/**
 * 恢复默认文本
 */
export function recover({ el = '.yc-loadbtn0' } = {}) {
  el = getElement(el)
  if (!el) {
    return false
  }

  change({
    el,
    txt: txtBak.get(el) ? txtBak.get(el) : '',
  })
}