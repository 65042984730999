// 常量
import * as constants from 'constants'
// 工具方法
import * as utils from 'utils'

/**
 * 获取 viewer 需要的大图 url 并赋给img元素
 * @param {Object | String} imgs 图片元素数组
 * @return {Boolean} 成功 true; 失败 false。
 */
export function getHdImg (options = {}) {
  options = Object.assign({}, options)

  if (!(initArgs(options) && ivArgs(options))) {
    return false
  }

  start(options)

  return true
}

/**
 * 参数初始化
 */
function initArgs (options = {}) {
  if (typeof options.imgs === 'string') {
    let o = document.querySelectorAll(options.imgs)

    if (o.length === 0) {
      return error()
    }

    options.imgs = o
  }

  return true

  function error () {
    utils.log.e(constants.tips.console.domElNotFound)
    return false
  }
}

/**
 * 参数校验
 */
function ivArgs (options = {}) {
  let {
    imgs,
  } = options

  if (imgs instanceof NodeList) {
    for (let o of imgs) {
      if (!(o instanceof Image)) {
        return error()
      }
    }
  } else {
    return error()
  }

  return true

  function error () {
    utils.log.e(constants.tips.console.ivArgs)
    return false
  }
}

function start (options = {}) {
  let {
    imgs,
  } = options

  for (let o of imgs) {
    o.setAttribute(constants.propertyName.viewer.hdUrl, o.getAttribute('src').replace(constants.regExp.img.hdUrlFilter, ''))
  }
}