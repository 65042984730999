/**
 * 正则表达式
 */
export const regExp = {
  // 礼包相关
  gift: {
    expiryDate: /[0-9~/]+$/g, // 有效期
  },
  // 图片过滤
  img: {
    base64: /base64/, // base64 简单校验
    errorUrl: /img-error_\d+_\d+.jpg/, // 加载失败默认图 url。防拉伸方法检测到此url时不增减样式
    hdUrlFilter: /_\d+x\d+/i, // 满足正则表达式的部分替换为 '' 过滤出高清图URL(游戏截图)
  },
  // 游戏安装包相关
  package: {
    breakjail: /jailbreak=Y/, // 越狱包链接
    nonBreakJail: /jailbreak=N/, // 非越狱包链接
  },
  // 搜索相关
  search: {
    paramsSplit: /[?&][^?&]+=[^?&]+/g // 各参数分离
  },
  // url 相关
  url: {
    htmlName: /\/\w*.s?html/, // html、shtml 文件名
    paramsSplit: /[?&][^?&]+=[^?&]+/g, // 各参数分离
    pkg: /\?link=/, // url 传递的 ios 越狱包下载地址
  },
  // 用户
  user: {
    email: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, // 邮箱。需修改，目前未对仅存在_-的情况检验。
    phoneNum: /1[\d]{10}/, // 手机号码
  }
}