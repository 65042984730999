// 常量
import * as constants from 'constants'
// 工具方法
import * as utils from 'utils'

/**
 * 换一批
 * @param {String} activeClass 激活类名
 * @param {Object | String} els 列表元素 NodeList 对象
 * @param {Object | String} nextEL 后向触发元素
 * @param {Boolean} optional 可选。true 未初始化不报错； false 摆错
 * @param {Object | String} prevEl 前向触发元素
 * @return {Boolean} 成功 true; 失败 false
 */
export function init (options = {}) {
  options = Object.assign({}, options)

  if (!(argsInit(options) && argsValidate(options))) {
    return false
  }

  start(options)

  return true
}

/**
 * 参数初始化
 */
function argsInit (options) {
  let {
    activeClass = 'active',
    optional = false,
  } = options

  options.activeClass = activeClass
  options.optional = optional

  if (typeof options.els === 'string') {
    let o = document.querySelectorAll(options.els)

    if (o.length === 0) {
      return optional ? false : error()
    }

    options.els = o
  }

  if (typeof options.nextEl === 'string') {
    let o = document.querySelector(options.nextEl)

    if (!o) {
      return error()
    }

    options.nextEl = o
  }

  if (typeof options.prevEl === 'string') {
    let o = document.querySelector(options.prevEl)

    if (!o) {
      return error()
    }

    options.prevEl = o
  }

  return true

  function error() {
    utils.log.e(constants.tips.console.domElNotFound)
    return false
  }
}

/**
 * 参数校验
 */
function argsValidate (options) {
  let {
    activeClass,
    els,
    nextEl,
    optional,
    prevEl,
  } = options

  if (typeof activeClass !== 'string' || !(els instanceof NodeList) || typeof optional !== 'boolean') {
    return error()
  }

  if(!((nextEl && nextEl instanceof HTMLElement) || (prevEl && prevEl instanceof HTMLElement))) {
    return error()
  }

  return true

  function error() {
    utils.log.e(constants.tips.console.ivArgs)
    return false
  }
}

/**
 * 事件回调
 */
function handler(options) {
  let {
    activeClass,
    els,
    nextEl,
  } = options

  if (els.length < 2) {
    // 可切换列表为 0 或 1，无需切换
    return
  }

  let index = Array.from(els).findIndex(el => {
    return el.classList.contains(activeClass)
  }) // 选中列表索引

  let dp // 待显示列表索引
  
  if (this === nextEl) {
    // 后向事件。未到队尾，显示后一批; 已到达队尾，显示第一批。
    dp = index < els.length - 1 ? els[index + 1] : els[0]
  } else {
    // 前向事件。未到队首，显示前一批; 已到达队首，显示最后一批。
    dp = index > 0 ? els[index - 1] : els[els.length - 1]
  }

  els[index].classList.remove(activeClass)
  dp.classList.add(activeClass)
}

function start (options = {}) {
  let {
    nextEl,
    prevEl,
  } = options

  // 清除已经存在的绑定，释放内存
  if (nextEl) {
    nextEl.removeEventListener('click', handler)
    nextEl.addEventListener('click', function() {
      handler.call(this, options)
    }, false)
  }

  if (prevEl) {
    prevEl.removeEventListener('click', handler)
    prevEl.addEventListener('click', function() {
      handler.call(this, options)
    }, false)
  }
}
