// jQuery
import $ from 'jQuery'
// 常量
import * as constants from 'constants'
// 工具方法
import * as utils from 'utils'

/**
 * 隐藏遮罩
 * @param {String} selector CSS选择器
 * @param {Boolean} isPaused 禁用滚动
 */
export function hide({
  selector,
  isPaused = false,
} = {}) {
  let obj = document.querySelector(selector)

  if (obj) {
    if (isPaused) {
      utils.fix.scrollPenetration.whenMaskHide(obj)
    }
    obj.style.display = 'none';
    obj.style.visibility = 'hidden'
  }
}

/**
 * 显示遮罩
 * @param {String} selector CSS选择器
 * @param {Boolean} isPaused 禁用滚动
 */
export function show({
  selector = '.yui-mask',
  isPaused = false,
} = {}) {
  let obj = document.querySelector(selector)

  if (obj) {
    if (isPaused) {
      utils.fix.scrollPenetration.whenMaskShow(obj)
    }
    obj.style.display = 'block';
    obj.style.visibility = 'visible'
  }
}

/**
 * 生成遮罩
 * @param {String} bgColor 背景色 rgba 值
 * @param {Function} callback 点击遮罩回调
 * @param {String} selector css 选择器
 * @param {Array} slot 镶嵌槽位
 * @param {String} target 遮罩挂载节点或它的CSS 选择器
 */
export function create(options = {}) {
  let {
    bgColor = '255, 255, 255, 0.3',
      callback = () => {},
      selector = '',
      slot = [],
      target = 'body',
  } = options
  let topContent = '' // 顶部盒子镶嵌内容
  let centerContent = '' // 中央盒镶嵌内容

  for (let obj of slot) {
    switch (obj.name) {
      case 'top-box':
        topContent = obj.content
        break
      case 'center-box':
        centerContent = obj.content
        break
      default:
        break
    }
  }

  let mask = $(`
    <div>
      <div class="top-box">
        ${topContent}
      </div>
      <div class="center-box">
        ${centerContent}
      </div>
    </div>
  `)[0]

  if (selector.indexOf('#') === 0) {
    // id 选择器
    mask.setAttribute('id', selector.substr(1))
  } else if (selector.indexOf('.') === 0) {
    // class 选择器
    $(mask).addClass(selector.substr(1))
  }

  mask.style.backgroundColor = `rgba(${bgColor})`
  mask.addEventListener('click', function (evt) {
    if (evt.target === this) {
      callback.bind(this)(evt)
    }
  }, false)

  if (target instanceof HTMLElement) {
    target.append(mask)
  } else if (typeof target === 'string') {
    let obj = document.querySelector(target)

    if (!obj) {
      utils.log.e(constants.tips.console.domElNotFound)
      return
    }

    obj.append(mask)
  }
}
