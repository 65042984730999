// jQuery
import $ from 'jquery'
// API
import {
  getGift,
} from 'api'
// 常量
import * as constants from 'constants'
// 实体
import {
  GiftEntity,
} from 'js/entity'
// 公共方法
import {
  dataBus,
  message,
} from 'js/common'
// 工具方法
import * as utils from 'utils'

/**
 * 领取礼包初始化
 * @param {Function} callback 领取成功回调
 * @param {Object || String} [els] 按钮
 */
export function init({ callback = () => {}, els = '.yc-giftbtn0' } = {}) {
  if (typeof els === 'string') {
    els = document.querySelectorAll(els)

    if (els.length === 0) {
      utils.log.e(constants.tips.console.domElNotFound)
      return false
    }
  }

  if (typeof callback !== 'function') {
    return error()
  }

  if (!(els instanceof NodeList)) {
    return error()
  }


  bindEvts({
    callback,
    els,
  })

  function error() {
    utils.log.e(constants.tips.console.ivArgs)
    return false
  }
}

/**
 * 事件初始化
 */
function bindEvts(options = {}) {
  let {
    callback,
    els,
  } = options

  for (let el of els) {
    $(el).one('click', async function (evt) {
      let user = dataBus.get({
        key: 'entities',
      }).get('user')
  
      if (!user) {
        // 未登录
        return message.common.pleaseLogin()
      }
  
      let id = this.getAttribute(constants.propertyName.gift.id)
  
      if (!id) {
        return error(ret && ret.msg ? ret.msg : undefined)
      } else {
        id = Number.parseInt(id)
      }
  
      let ret
      try {
        ret = await getGift({
          giftId: id
        })
      } catch (e) {
        if (e && e.message) {
          utils.log.e(e.message)
        }
      }
  
      if (ret && ret.isOk() && ret.data) {
        // 礼包领取成功
        user.gifts.push(new GiftEntity({
          code: ret.data.giftCode ? ret.data.giftCode : '',
          id,
        }))
        message.success({
          timeoutCallback: callback,
          txt: constants.tips.gift.get.success,
        })
      } else {
        // 礼包领取失败
        return error(ret && ret.msg ? ret.msg : undefined)
      }
    })
  }

  function error(txt = constants.tips.gift.get.fail) {
    utils.log.e(txt)
    message.error({
      txt: constants.tips.gift.get.fail,
    })
    bindEvts(options)
  }
}
