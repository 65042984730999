import { ajax, Result } from 'js/api/index'

/**
 * 用户已领取礼包查询
 */
export async function memGifts ({ usetype = 1 } = {}) {
  let ret = await ajax.post({
    url: 'api/appgift/received_data',
    data: {
      columns: 'begintime,endtime,gameid,giftcode,id,link,name,thumnaillogofile',
    }
  })

  return new Result({
    data: ret._RESULT,
    msg: ret._Message,
    status: ret._Status,
  })
}
