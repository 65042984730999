/**
 * 获取游戏热度颜色
 * @param {Number | String} v 热度值
 * @return {String} 热度类名
 */
export function getGameTemp(v) {
  if (typeof v === 'string') {
    v = Number.parseInt(v)
  }

	let color = 'yellow'

	if (v > 80) {
		color = 'red'
	} else if (v <= 60) {
		color = 'blue'
	}

	return color
}