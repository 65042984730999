// 常量
import * as constants from 'constants'
//工具方法
import * as utils from 'utils'

import {
  mask,
} from 'js/common/ui/mask/index'
import {
  wechatMask
} from './wechatMask'

let hasMask = false // 微信遮罩存在标识

/**
 * 下载按钮初始化
 * @param {Boolean} [attrOnly] 仅初始化属性，不初始化事件
 * @param {Number} [browser] 浏览器类型
 * @param {String} [btnClass] 按钮类名。用于事件监听
 * @param {String} disableClass 无下载包状态 class
 * @param {Object | String} el 下载按钮
 * @param {Number} platform 平台
 * 安卓 / IOS 微信浏览器弹出 tips。
 * 非微信浏览器，安卓直接下载；
 * IOS 官方包直接跳转到 app store；
 * IOS 越狱包手机百度，跳转到手机百度指导页，复制 ios 越狱包指导页链接；
 * IOS 越狱包非手机百度跳转到 ios 越狱包指导页；
 */
export function init(options = {}) {
  options = Object.assign({}, options)

  if (!(initArgs(options) && ivArgs(options))) {
    return false
  }

  start(options)
}

/**
 * 参数初始化
 */
function initArgs(options = {}) {
  let {
    attrOnly = false,
  } = options

  options.attrOnly = attrOnly

  if (typeof options.el === 'string') {
    let o = document.querySelectorAll(options.el)
    if (o.length === 0) {
      return error()
    }

    options.el = o
  }

  return true

  function error() {
    utils.log.e(constants.tips.console.domElNotFound)
    return false
  }
}

/**
 * 参数校验
 */
function ivArgs(options = {}) {
  let {
    attrOnly,
    browser,
    btnClass,
    disableClass,
    el,
    platform,
  } = options

  if (typeof attrOnly !== 'boolean') {
    return error()
  }

  if (browser) {
    let rs
    for (let o of Object.values(constants.ua.browser)) {
      if (browser === o) {
        rs = true
      }
    }
    if (!rs) {
      return error()
    }
  }

  if (btnClass && typeof btnClass !== 'string') {
    return error()
  }

  if (typeof disableClass !== 'string') {
    return error()
  }

  if (!(el instanceof NodeList || el instanceof HTMLElement)) {
    return error()
  }

  let rs = false
  for (let o of Object.values(constants.ua.platform)) {
    if (platform === o) {
      rs = true
    }
  }
  if (!rs) {
    return error()
  }

  return true

  function error() {
    utils.log.e(constants.tips.console.ivArgs)
    return false
  }
}

/**
 * 按钮属性及样式初始化
 */
function downloadAttrInit(options = {}) {
  let {
    browser,
    disableClass,
    el,
    platform,
  } = options

  if (browser === constants.ua.browser.wechatBrowser) {
    // 微信浏览器
    if (!hasMask) {
      wechatMask()
      hasMask = true
    }
    return
  }

  for (let btn of el instanceof HTMLElement ? [el] : el) {
    if (btn.classList.contains(disableClass)) {
      // 无包，服务器端渲染时已增加无包样式
      continue
    }

    let link = btn.getAttribute(constants.propertyName.download.url[platform === constants.ua.platform.ios ? 'ipa' : 'apk']) // 原链接

    if (!link || (typeof link === 'string' && link === 'javascript:void(0)')) {
      // 无包，服务器端渲染时未处理，增加无包样式
      btn.classList.add(disableClass);
      // seo下载文案替换
      if (btn.classList.contains('yc-dbtn1')) {
        // 当前客户端无包文案
        let txt = platform === constants.ua.platform.android ? '此游戏无安卓版本' : '此游戏无ios版本';
        // 即将上线和停止运营文案
        if (btn.getAttribute('data-status') === 'stopRunning' || btn.getAttribute('data-status') === 'soonOnline') {
          txt = btn.getAttribute('data-status') === 'stopRunning' ? '此游戏已停止运营' : '此游戏即将上线';
        }
        
        btn.innerText = txt;
      }
      continue
    }

    if (platform === constants.ua.platform.ios) {
      // ios
      if (constants.regExp.package.breakjail.test(link)) {
        // 越狱包
        if (process.env.NODE_ENV === 'production') {
          // 生产环境
          link = `${prefix}${browser === constants.ua.browser.mobileBaiDu ? constants.url.prod.ig.mb : constants.url.prod.ig.nonMb}${prefix}${link}`
        } else {
          // 开发环境
          link = `${browser === constants.ua.browser.mobileBaiDu ? constants.url.dev.ig.mb : constants.url.dev.ig.nonMb}${prefix}${link}`
        }
        
      } else if (constants.regExp.package.nonBreakJail.test(link)) {
        // 官方包
        
        // aso需求，Y: href赋值当前游戏详情URL  N: href赋值下载URL
        link = btn.getAttribute('data-aso') === 'Y' ? btn.parentElement.previousElementSibling.getAttribute('href') : `${prefix}${link}`
      } else {
        utils.log.e('invalid ipa type')
      }
    }

    if (platform === constants.ua.platform.android) {
      // 安卓
      link = `${prefix}${link}`
    }

    btn.setAttribute('href', link + '&js=1');
  }
}

/**
 * 下载事件初始化
 */
function downloadEvtInit(options = {}) {
  let {
    browser,
    btnClass,
  } = options

  if (!btnClass) {
    utils.log.e(constants.tips.console.ivArgs)
    return false
  }

  document.querySelector('body').addEventListener('click', function (e) {
    let el = e.target

    if (!el) {
      // 元素已经被移除
      return
    }

    while (!el.classList.contains(btnClass)) {

      if (el === this) {
        return
      }

      el = el.parentNode

      if (!el) {
        return
      }
    }

    handler(e)
  }, false)

  function handler(e) {
    if (browser === constants.ua.browser.wechatBrowser) {
      // 微信浏览器
      mask.show({
        selector: '.yc-mask-wechat'
      })
      e.preventDefault()
      return false
    }

    // android / ios 直接跳转
  }
}

function start(options = {}) {
  let {
    attrOnly,
  } = options

  downloadAttrInit(options)

  if (attrOnly) {
    return
  }

  downloadEvtInit(options)
}