// 常量
import * as constants from 'constants'
// 工具方法
import * as utils from 'utils'

/**
 * 块导航标签切换
 * @param {String} activeClass 激活类名称
 * @param {Object | String} aEl "更多" 元素
 * @param {Function} callback 回调方法
 * @param {Object | String} blks 待切换块
 * @param {Object} tags 块标签
 */
export function init(options = {}) {
  options = Object.assign({}, options)

  if (!(initArgs(options) && ivArgs(options))) {
    return false
  }

  start(options)
}

/**
 * 参数初始化
 */
function initArgs(options = {}) {
  let {
    activeClass = 'active',
      callback = () => {},
  } = options

  options.activeClass = activeClass
  options.callback = callback

  if (typeof options.aEl === 'string') {
    let o = document.querySelector(options.aEl)

    if (!o) {
      return error()
    }

    options.aEl = o
  }

  if (typeof options.blks === 'string') {
    let o = document.querySelectorAll(options.blks)

    if (o.length === 0) {
      return error()
    }

    options.blks = o
  }

  if (typeof options.tags === 'string') {
    let o = document.querySelectorAll(options.tags)

    if (o.length === 0) {
      return error()
    }

    options.tags = o
  }

  return true

  function error() {
    utils.log.e(constants.tips.console.domElNotFound)
    return false
  }
}

/**
 * 参数校验
 */
function ivArgs(options = {}) {
  let {
    activeClass,
    aEl,
    blks,
    callback,
    tags,
  } = options

  if (typeof activeClass !== 'string') {
    return error()
  }

  if (aEl && !(aEl instanceof HTMLElement)) {
    return error()
  }

  if (!(blks instanceof NodeList)) {
    return error()
  }

  if (typeof callback !== 'function') {
    return error()
  }

  if (!(tags instanceof NodeList)) {
    return error()
  }

  return true

  function error() {
    utils.log.e(constants.tips.console.domElNotFound)
    return false
  }
}

function start(options = {}) {
  let {
    activeClass,
    aEl,
    blks,
    callback,
    tags,
  } = options

  for (let tag of tags) {
    tag.addEventListener('click', handler, false)
  }

  function handler(evt) {
    // 切换块标签
    let index = Array.from(tags).findIndex(el => {
      return el === this
    }) // 选中标签索引

    select(index)

    callback(this)
  }

  /**
   * 选中标签
   * @param {Number} index 选中标签索引
   */
  function select(index = 0) {
    for (let tag of tags) {
      tag.classList.remove(activeClass)
    }

    for (let blk of blks) {
      blk.classList.remove(activeClass)
    }

    tags[index].classList.add(activeClass)
    blks[index].classList.add(activeClass)

    if (aEl instanceof HTMLElement) {
      aEl.setAttribute('href', tags[index].getAttribute(constants.propertyName.blkNavTag.aTagUrl))
    }
  }
}