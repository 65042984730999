// 常量
import * as constants from 'constants'

import { log } from 'utils/log'

/**
 * url 解析器
 */
export class UrlParser {
  constructor(options = {}) {
    let {
      url = window.location.href,
    } = options
    this.url = url // 当前窗口 url
    this.params = this.parseParams() // 当前窗口 url 参数
    this.htmlName = this.parseHtmlName() // html 文件名
    this.domainName = this.parseDomainName() // 域名
  }

  /**
   * 过滤域名
   */
  parseDomainName ({
    url = this.url,
  } = {}) {
    let o = url.split('/')
    if (o.length > 2) {
      o = o.slice(0, 3).join('/')
    } else {
      log.e(constants.tips.console.ivArgs)
      o = undefined
    }

    return o
  }

  /**
   * 过滤 url 参数
   * @param {String} [url]
   * @return {Array || Boolean} 参数数组 或 false
   */
  parseParams({
    url = this.url,
  } = {}) {
    // 从 URL 取参数
    let params = decodeURI(url).match(constants.regExp.url.paramsSplit)

    if (params === null) {
      return false
    }

    // 参数过滤
    return paramFilter({
      params
    })
  }

  /**
   * 过滤 html 文件名
   */
  parseHtmlName ({
    url = this.url,
  } = {}) {
    let name = url.match(constants.regExp.url.htmlName)

    if (name === null) {
      // 未解析到 html 后缀
      return undefined
    }

    name = name[0].substr(1)
    name = name.substring(0, name.length - 5)

    return name
  }
}

/**
 * 参数过滤
 * @param {Array} params 含有形如 (? || &)paramName=paramValue 元素的数组
 * @return {Object} 参数名值对象
 */
function paramFilter({
  params = []
} = {}) {

  if (!(params instanceof Array)) {
    log.e(constants.tips.console.ivArgs)
    return false
  }

  let obj = {}

  for (let o of params) {
    let pos = o.indexOf('=') // 等于号索引

    if (pos === -1) {
      log.e(constants.tips.console.ivArgs)
      continue
    }

    let key = '' // 参数名

    for (let i = 1; i < pos; i++) {
      key += o[i]
    }

    // 初始化参数名
    obj[key] = ''
    
    pos++
    while (typeof o[pos] !== 'undefined') {
      obj[key] += o[pos++] // 参数值
    }
  }

  return obj
}
