import { ajax, Result } from 'js/api/index'

/**
 * 获取礼包信息(人气、热门、新发礼包榜)
 * @param {String} columns 返回值包含的字段
 * @param {Number} giftId 礼包ID 获取单个礼包信息
 * @param {String} keyword 搜索关键字
 * @param {String} orderType 排序方式 {hot 领取数; recent 时间; pop 人气}
 * @param {String} popType 人气礼包榜类型。 1 最近领取
 * @keyword {String} 名字关键词
 * 
 */
export async function getGiftInfo ({ columns = 'begintime,content,endtime,gameid,gamename,id,link,name,publishdate,quantity,gamelogofile,total', giftId, keyword = '', orderType = '', popType='', pageSize = '', pageIndex = '' } = {}) {
  let ret = await ajax.post({
    url: 'api/app/giftdata',
    data: {
      columns,
      giftid: giftId,
      ordertype: orderType,
      pagesize: pageSize,
      pageindex: pageIndex,
      poptype: popType,
      keyword,
    }
  })

  let rs = new Result({
    data: ret._RESULT,
    msg: ret._Message,
    status: ret._Status,
    additionalData: typeof ret.total !== 'undefined' ? {
      total: ret.total
    } : null
  })

  return rs
}