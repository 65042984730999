// 常量
import * as constants from 'constants'
// 工具方法
import * as utils from 'utils'

/**
 * 移除空 tab
 */
export function rmEmptyTab(options = {}) {
  if (!(initArgs(options) && ivArgs(options))) {
    return false
  }

  uiInit(options)
}

/**
 * 参数初始化
 */
function initArgs(options) {
  if (typeof options.blks === 'string') {
    let o = document.querySelectorAll(options.blks)

    if (o.length === 0) {
      return error()
    }

    options.blks = o
  }

  if (typeof options.tabs === 'string') {
    let o = document.querySelectorAll(options.tabs)

    if (o.length === 0) {
      return error()
    }

    options.tabs = o
  }

  return true

  function error() {
    utils.log.e(constants.tips.console.domElNotFound)
    return
  }
}

/**
 * 参数校验
 */
function ivArgs({ blks, tabs }) {
  if (!(blks instanceof NodeList && tabs instanceof NodeList)) {
    return error()
  }

  if (blks.length === 0 || tabs.length === 0) {
    return error()
  }

  return true

  function error() {
    utils.log.e(constants.tips.console.ivArgs)
    return false
  }
}

/**
 * UI 初始化
 */
function uiInit({ blks, tabs }) {
  for (let [i, blk] of blks.entries()) {
    if (blk.children.length == 0) {
      blk.parentNode.removeChild(blk)
      tabs[i].parentNode.removeChild(tabs[i])
    }
  }
}