import { ajax, Result } from 'js/api/index'

/**
 * 搜索数据。
 * @param {Number} siteid 站点ID
 * @param {String} level 栏目层级 (level若为Root,可不传catalogalias)
 * @param {String} ordertype 排序方式{Recent:时间, Hot:热度}
 * @param {String} loadcontent 是否加载文章内容 {true, false}
 * @param {Number} appid 游戏ID
 * @param {String} catalogalias 栏目别名 {game_content_xwzx:新闻资讯, game_content_hdgg:活动公告, game_content_yxgl:游戏攻略, game_content_yxsp:游戏视频}
 * @param {Number} pageindex 分页页码
 * @param {Number} pagesize 分页大小
 * @param {String} columns 返回字段
 */
export async function getArticleList ({ siteId = '124', level = 'CurrentAndChild', orderType = 'Recent', loadContent = 'false', appId = '', catalogAlias  = '', pageIndex = '', pageSize = '20', columns='id,logofile,title,summary,link,publishdate' } = {}) {
  let ret = await ajax.post({
    url: 'api/app/contentdata',
    data: {
      siteid: siteId,
      level,
      ordertype: orderType,
      loadcontent: loadContent,
      appid: appId,
      catalogalias: catalogAlias,
      pageindex: pageIndex,
      pagesize: pageSize,
      columns
    }
  })

  let rs = new Result({
    data: ret._RESULT,
    msg: ret._Message,
    status: ret._Status,
    additionalData: typeof ret.total !== 'undefined' ? { total: ret.total } : null
  })

  return rs
}