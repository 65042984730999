// 组件
export * from 'components/input/copy'
// 常量
import * as constants from 'constants'
// 公共方法
import {
  message
} from 'js/common'

import {
  log
} from 'utils/log/index'

/**
 * 复制到剪辑版。优先复制，fallback 选中。
 * @param {Object | String} el 文本节点的父元素
 */
export function toClipBoard(options = {}) {
  options = Object.assign({}, options)

  if (!(initArgs(options) && ivArgs(options))) {
    return false
  }

  if (commadnWay(options)) {
    message.success({
      txt: constants.tips.copy.success,
    })
    return true
  }

  if (selectionWay(options)) {
    message.warning({
      txt: constants.tips.copy.manually,
    })
    return true
  }

  message.error({
    txt: constants.tips.copy.fail,
  })
}

/**
 * 参数初始化
 */
function initArgs(options = {}) {
  if (typeof options.el === 'string') {
    let o = document.querySelector(options.el)

    if (!o) {
      return error()
    }

    options.el = o
  }

  return true

  function error() {
    log.e(constants.tips.console.domElNotFound)
    return false
  }
}

/**
 * 参数校验
 */
function ivArgs(options = {}) {
  let {
    el,
  } = options

  if (!(el instanceof HTMLElement)) {
    return error()
  }

  return true

  function error() {
    log.e(constants.tips.console.ivArgs)
    return false
  }
}

/**
 * 浏览器命令-复制
 */
export function commadnWay(options = {}) {
  let {
    el,
  } = options

  if (!document.queryCommandSupported || (document.queryCommandSupported && !document.queryCommandSupported('copy'))) {
    log.d('浏览器不支持 copy 命令')
    return false
  }

  let ipt = document.createElement('input')
  ipt.setAttribute('class', 'yc-ipt-copy')
  ipt.value = el.innerText

  document.querySelector('body').appendChild(ipt)
  ipt.select()
  let rs = document.execCommand('copy')
  ipt.remove()

  return rs ? true : false
}

/**
 * Selection API
 */
export function selectionWay({ el } = {}) {
  // 优先 createRange
  if (window.getSelection && document.createRange) {
    el.setAttribute("contenteditable", true)
    let selection = window.getSelection()
    selection.removeAllRanges()
    let range = document.createRange()
    range.selectNodeContents(el)
    selection.addRange(range)

    return true
  }

  // IE 浏览器
  if (document.body.createTextRange) {
    el.setAttribute("contenteditable", true)
    let range = document.body.createTextRange()
    range.moveToElementText(el)
    range.select()

    return true
  }

  log.d('浏览器不支持 selection / createRange API')
  return false
}
