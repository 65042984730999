// 配置文件
import config from 'config/config'

/**
 * 错误提示
 */
export const tips = {
  // 控制台报错
  console: {
    domElNotFound: 'DOM element not found', // DOM 元素未找到
    ivArgs: 'invalid argument', // 参数非法
    sms: {
      typeWrong: 'invalid sms code type', // 类型错误
    },
    user: {
      gifts: `failed to query user's gifts`, // 查询已领取礼包失败
      lgStatus: `failed to query user's login status`, // 查询登录状态失败
      ivLgStatus: 'invalid login status', // 错误的登录状态
    }, // 用户
  },
  // 评论
  cmmt: {
    empty: '评论不能为空', // 空评论
    fail: '评论失败', // 评论失败默认提示
    length: `评论不能少于 ${config.cmmt.minLen} 个字`,
    load: {
      empty: '暂无评论', // 无
    }, // 加载
    score: {
      fail: '评分失败', // 评分失败默认提示
      success: '评分成功',
    },
    success: '评论成功',
  },
  // 兼容性
  compatibility: {
    updateBrowser: '浏览器版本较低，请更新浏览器', // 更新浏览器
  },
  // 复制
  copy: {
    fail: '复制失败，请长按文本手动复制', // 失败
    manually: '请长按文本手动复制', // 手动复制
    success: '复制成功', // 成功
  },
  // 下载
  download: {
    btn: {
      android: '官方下载', // 安卓
      breakJail: '立即安装', // 越狱包
      empty: '敬请期待', // 无包
      nonBreakJail: '<i class="u-icon-apple"></i>' + 'App Store 官 方 下 载', // 官方包
    }, // 按钮文本
    urlBox: {
      empty: '链接不存在', // 未找到 url 传递的下载链接
    },
  },
  // 礼包
  gift: {
    get: {
      fail: '礼包领取失败', // 失败
      success: '领取成功', // 成功
    }, // 领取
    info: {
      fail: '查询礼包信息失败', // 失败
    }, // 查询信息
    received: '已领', // 已领取礼包的按钮文本
    runOut: '领完', // 领完
  },
  // 加载
  load: {
    btn: {
      complete: '加载完毕', // 完毕
      fail: '加载失败', // 失败
      loading: '正在加载...', // 正在加载
    }, // 按钮
  },
  // 用户
  user: {
    avatar: {
      maxSize: `请上传不大于 ${config.avatar.maxSize} MB的图片`, // 大小
      upload: {
        fail: '上传头像失败', // 失败
        success: '上传头像成功', // 成功
        type: '仅支持 jpg/jpeg/png/gif 格式文件', // 格式
      } // 上传
    }, // 头像
    email: {
      formatWrong: '电子邮箱格式不正确', // 格式错误
    }, // 电子邮箱
    login: {
      fail: '登陆失败', // 失败
      please: '请先登录',
      success: '登陆成功', // 成功
    }, // 登录
    modifyInfo: {
      fail: '修改失败', // 失败
      success: '修改成功', // 成功 
    }, // 修改信息
    phoneNum: {
      format: '手机号码应为 11 位数字',
    }, // 手机号码
    pwd: {
      length: `密码应在 ${config.password.minLen} ~ ${config.password.maxLen} 位之间`
    }, // 密码
    reg: {
      fail: '注册失败', // 注册失败默认提示
      success: '注册成功',
    }, // 注册
    resetPwd: {
      success: '密码重置成功', // 成功
    }, // 改密
    signature: {
      default: '这个人很懒，什么也没留下..' // 默认
    }, // 签名
    sign: {
      fail: '签到失败', //失败
      queryFail: '查询签到失败', // 查询失败
      success: '签到成功', // 成功
    }, // 签到
  },
  // 验证码
  vfCode: {
    img: {
      formatWrong: '图形验证码格式不正确',
    }, // 图形验证码
    sms: {
      fail: '发送验证码失败', // 失败
      formatWrong: '短信验证码格式不正确',
    }, // 短信验证码
  }
}