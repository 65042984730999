// 常量
import * as constants from 'constants'
// 工具方法
import * as utils from 'utils'

/**
 * 悬浮导航栏事件
 * @param {String} activeClass 激活类名
 * @param {Number} space 检测点与导航栏间距。
 * @return {Boolean} 成功 true; 失败 false。
 */
export function init(options = {}) {
  let {
    activeClass = 'active',
    space = 0,
  } = options

  if (typeof activeClass !== 'string' || typeof space !== 'number') {
    utils.log.e(constants.tips.console.ivArgs)
    return false
  }

  let el = document.querySelector('.yc-nav0')
  if (!el) {
    utils.log.e(constants.tips.console.domElNotFound)
    return false
  }

  bindEvts({
    activeClass,
    el,
    space,
  })
  return true
}

/**
 * 悬浮监听初始化
 */
function bindEvts (options = {}) {
  let {
    activeClass,
    el,
    space,
  } = options

  const handler = typeof el.getBoundingClientRect().top !== 'undefined' ? method0 : method1

  handler()

  window.addEventListener('scroll', utils.throttle({
    fn: handler,
    mode: 1,
  }), utils.compability.passiveEvtSupported() ? {
    passive: true
  } : false)

  /**
   * getBoundingClientRect 计算方式(工作草案)
   */
  function method0() {
    el.classList[el.getBoundingClientRect().top + space < 0 ? 'add' : 'remove'](activeClass)
  }

  /**
   * scrollTop、offsetTop 计算方式。如果定位父元素不是根元素，offsetTop 可能不正确
   */
  function method1() {
    el.classList[(window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop) > el.offsetTop + space ? 'add' : 'remove'](activeClass)
  }
}