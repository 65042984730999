import { ajax, Result } from 'js/api/index'

/**
 * 好玩 / 不好玩
 * @param {Number} commentId 评论ID
 */
export async function good ({ type, gameId } = {}) {
  let ret = await ajax.post({
    url: 'api/app/like',
    data: {
      type,
      appid: gameId,
    }
  })

  return new Result ({
    data: ret._RESULT,
    msg: ret._Message,
    status: ret._Status
  })
}
