const dBusSyb = Symbol('bus') // 数据总线映射不允许直接访问

/**
 * 数据总线。单例。
 */
class DataBus {
  constructor () {
    this[dBusSyb] = new Map() // 数据总线映射
  }

  /**
   * 清理数据总线
   */
  clear () {
    this[dBusSyb].clear()
  }

  /**
   * 删除键值对
   * @param {String} key 键。
   * @return {Boolean} true 删除成功；false 删除失败。
   */
  delete (options = {}) {
    let {
      key = ''
    } = options

    return this[dBusSyb].delete(key)
  }

  /**
   * 取值
   * @param {String} key 键。
   * @return {Any} 值。
   */
  get (options = {}) {
    let {
      key = '',
    } = options

    return this[dBusSyb].get(key)
  }

  /**
   * 键值对是否存在
   * @param {String} key 键。
   * @return {Boolean} true 存在；false 不存在。
   */
  has (options = {}) {
    let {
      key = '',
    } = options

    return this[dBusSyb].has(key)
  }

  /**
   * 设置键值对
   * @param {String} key 键
   * @param {String} Value 值
   * @return {Object} 数据总线对象
   */
  set (options = {}) {
    let {
      key = '',
      value,
    } = options

    this[dBusSyb].set(key, value)
    return this
  }
}

const dataBus = new DataBus()

export {
  dataBus,
  dBusSyb,
}
