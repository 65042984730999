// 常量
import * as constants from 'constants'
// 工具方法
import * as utils from 'utils'

/**
 * 重设根元素字号大小。
 * @param {Number} [orgFtSize] 设计稿尺寸根元素字号
 * @param {Number} [orgWt] 设计稿宽度
 */
export function resetFtSz (options = {}) {
  if (!(initArgs(options) && ivArgs(options))) {
    return false
  }

  start(options)

  return true
}

/**
 * 参数初始化
 */
function initArgs (options = {}) {
  let {
    orgFtSize = 100,
    orgWt = 750,
  } = options
  
  options.orgFtSize = orgFtSize
  options.orgWt = orgWt

  return true
}

/**
 * 参数校验
 */
function ivArgs (options = {}) {
  if (typeof options.orgFtSize !== 'number' || typeof options.orgWt !== 'number') {
    return error()
  }

  return true

  function error () {
    utils.log.e(constants.tips.console.ivArgs)
    return false
  }
}

function start (options = {}) {
  let {
    orgFtSize,
    orgWt,
  } = options

  handler()

  window.addEventListener('resize', utils.throttle({
    fn: handler,
  }), false)

  function handler () {
    document.querySelector('html').style.fontSize = `${utils.scale.getScaleRatio(orgWt) * orgFtSize}px`
  }
}
