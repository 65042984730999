import { ajax, Result } from 'js/api/index'

/**
 * 获取评论列表
 * @param {String} columns 请求的字段
 * @param {String} relaType 关联内容类型
 * @param {String} gameId 游戏ID
 * @param {Number} pageIndex 页码
 * @param {Number} pageSize 每页大小
 */
export async function getCmmt (options = {}) {
  let {
    columns = 'id,ip,addtime,content,memberlogo,nickname,support,SupportOrAgainst,username',
    commentId = null,
    // dataTable = 'Y',
    relaType = 'App',
    gameId,
    pageIndex = 0,
    pageSize = 5,
  } = options

  let ret = await ajax.post({
    url: 'api/app/commentdata',
    data: {
      columns,
      commentid: commentId,
      relaid: gameId,
      relatype: relaType,
      pageindex: pageIndex,
      pagesize: pageSize
    }
  })

  let rs = new Result({
    data: typeof ret.data !== 'undefined' ? ret.data : null,
    msg: typeof ret._Message !== 'undefined' ? ret._Message : null,
    status: typeof ret._Status !== 'undefined' ? ret._Status : null,
    additionalData: typeof ret.total !== 'undefined' ? { total: ret.total } : null
  })

  return rs
}