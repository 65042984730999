import {
  ajax,
  Result
} from 'js/api/index'

/**
 * 搜索数据
 * @param {Number} siteId 站点ID
 * @param {Number} ordertype 排序方式{1:下载量,2:更新时间}
 * @param {String} clienttype 客户端类型 {apk:安卓, ipa:苹果}
 * @param {String} query 查询值
 * @param {String} tags 例('126,131')
 * @param {Number} pageindex 分页页码
 * @param {Number} pagesize 分页大小
 * @param {String} columns 返回字段
 */
export async function searchData({
  clientType = '',
  columns = 'id,logofile,thumnaillogofile,link,downloadlink,name,downloadnum,instruction,score,tags,proscore,filesize',
  orderType = '1',
  pageIndex = '',
  pageSize = '',
  query = '',
  siteId = '124',
  tags = '',
} = {}) {
  let ret = await ajax.post({
    url: 'api/app/searchdata_new',
    data: {
      clienttype: clientType,
      columns,
      ordertype: orderType,
      pageindex: pageIndex,
      pagesize: pageSize,
      query,
      tags,
      siteid: siteId,
    }
  })

  let rs = new Result({
    data: ret._RESULT,
    msg: ret._Message,
    status: ret._Status,
    additionalData: typeof ret.total !== 'undefined' ? {
      total: ret.total
    } : null
  })

  return rs
}

/**
 * 搜索数据
 * @param {Number} siteId 站点ID
 * @param {Number} ordertype 排序方式{1:下载量,2:更新时间}
 * @param {String} clienttype 客户端类型 {apk:安卓, ipa:苹果}
 * @param {String} query 查询值
 * @param {String} tags 例('126,131')
 * @param {Number} cata {1:新闻, 2:攻略, 3:活动}
 * @param {Number} pageindex 分页页码
 * @param {Number} pagesize 分页大小
 * @param {String} columns 返回字段
 */
export async function searchContent({
  siteId = '124',
  orderType = '1',
  clientType = '',
  query = '',
  tags = '',
  cata,
  pageIndex = '',
  pageSize = '',
  columns = 'id,logofile,title,summary,url,publishdate'
} = {}) {
  let ret = await ajax.post({
    url: 'api/app/searchcontent',
    data: {
      siteid: siteId,
      ordertype: orderType,
      clienttype: clientType,
      query,
      tags,
      cata,
      pageindex: pageIndex,
      pagesize: pageSize,
      columns
    }
  })

  let rs = new Result({
    data: ret._RESULT,
    msg: ret._Message,
    status: ret._Status,
    additionalData: typeof ret.total !== 'undefined' ? {
      total: ret.total
    } : null
  })

  return rs
}