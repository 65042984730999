import { ajax, Result } from 'js/api/index'

/**
 * 评分
 * @param {String} gameId 游戏ID
 * @param {String} relaType 关联资源类型。 Game 游戏。
 * @param {String} siteId 站点ID
 * @param {Array} score 评分对象数组，允许多个不同类型评分。type: GameFace 表情评分。 score 分数。
 */
export async function score (options = {}) {
  let {
    gameId = '',
    relaType = 'Game',
    siteId = siteid ? siteid : '124',
    score,
  } = options

  let ret = await ajax.post({
    url: 'api/simplecomment/scoresubmit',
    data: {
      relaid: gameId,
      relatype: relaType,
      siteid: siteId,
      score: `[${score.join()}]`,
    }
  })

  return new Result ({
    data: ret._RESULT,
    msg: ret._Message,
    status: ret._Status
  })
}
