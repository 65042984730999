import {
  ajax,
  Result
} from 'js/api/index'

/**
 * 会员信息修改
 * @param {String} email 邮箱
 * @param {String} nickName 昵称
 * @param {String} gender 性别
 * @param {String} signature 个性签名
 */
export async function memDataModify({
  email,
  gender,
  logo,
  nickName,
  signature
} = {}) {
  let data = {}

  if (email) {
    data.email = email
  }
  if (gender) {
    data.gender = gender
  }
  if (logo) {
    data.logo = logo
  }
  if (nickName) {
    data.nickname = nickName
  }
  if (signature) {
    data.signature = signature
  }

  let ret = await ajax.post({
    url: 'api/member/sdksaveinfo',
    data,
  })

  return new Result({
    msg: ret._Message,
    status: ret._Status,
  })
}