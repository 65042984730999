import * as constants from 'js/constants/index'

/**
 * API 执行结果
 * @param {Array} data 数据
 * @param {String} msg 消息
 * @param {Number} status 结果状态码。 0 失败；1 成功
 * @param {Number} addtionalData 附加参数
 */
export class Result {
  constructor (options = {}) {
    let {
      data = null,
      msg = null,
      status = null,
      additionalData = null,
    } = options

    this.data = data
    this.msg = msg
    this.status = status
    this.additionalData = additionalData
  }

  isOk () {
    return this.status === constants.api.succeed
  }

  /**
   * 获取附加数据
   * @param {String} name 属性名
   */
  getAdditionalData (name) {
    let data = this.additionalData !== null && typeof this.additionalData[name] !== 'undefined' ? this.additionalData[name] : null
    
    return data
  }
}