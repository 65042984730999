// api
import {
  memDataModify,
  memGifts,
  memSignIn,
  memSignInQuery,
} from 'api'
// 常量
import * as constants from 'constants'
// 实体
import {
  GiftEntity,
} from 'js/entity'
// 工具方法
import * as utils from 'utils'

/**
 * 用户实体
 */
export class UserEntity {
  constructor(options = {}) {
    let {
      avatarUrl = '',
        email = '',
        gender = '',
        gifts = [],
        keepSignIn = 0,
        level = '',
        lgStatus = false,
        nickName = '',
        phoneNum = '',
        score = 0,
        signature = '',
        signInDate = [],
        userName = '',
    } = options

    this.avatarUrl = avatarUrl
    this.email = email
    this.gender = gender
    this.gifts = gifts
    this.keepSignIn = keepSignIn
    this.level = level
    this.lgStatus = lgStatus
    this.nickName = nickName
    this.phoneNum = phoneNum
    this.score = score
    this.signature = signature
    this.signInDate = signInDate
    this.userName = userName
  }

  /**
   * 获取礼包
   * @param {Number} id 礼包 ID
   * @return {Object} 礼包实体
   */
  getGift(id) {
    if (typeof id === 'number') {
      for (let g of this.gifts) {
        if (g.id === id) {
          return g
        }
      }
    }

    return undefined
  }

  /**
   * 登录状态设置为已登录
   */
  logined() {
    this.lgStatus = true
  }

  /**
   * 修改用户信息
   */
  async modifyInfo({
    data,
  } = {}) {
    let ret

    try {
      ret = await memDataModify({
        ...data
      })
    } catch (e) {
      if (e && e.message) {
        utils.log.e(e.message)
      }

      error()
    }

    if (ret && ret.isOk()) {
      return true
    } else {
      error(ret && ret.msg ? ret.msg : undefined)
    }

    function error(txt = constants.tips.user.modifyInfo.fail) {
      throw new Error(txt)
    }
  }

  /**
   * 查询已领取礼包
   */
  async queryGifts() {
    let ret
    try {
      ret = await memGifts()
    } catch (e) {
      if (e && e.message) {
        utils.log.e(e.message)
      }

      error()
    }

    if (ret && ret.isOk() && ret.data instanceof Array) {
      for (let d of ret.data) {
        this.gifts.push(new GiftEntity({
          code: d.giftcode ? d.giftcode : '',
          expTime: d.endtime ? d.endtime : 0,
          gameId: d.gameid ? d.gameid : 0,
          id: d.id ? d.id : 0,
          link: d.link ? d.link : '',
          name: d.name ? d.name : '',
          startTime: d.begintime ? d.begintime : 0,
          thumbnailImgUrl: d.thumnaillogofile ? d.thumnaillogofile : '',
        }))
      }
    } else {
      error(ret && ret.msg ? ret.msg : constants.tips.console.user.gifts)
    }

    function error(txt = '') {
      throw new Error(txt)
    }
  }

  /**
   * 设置已领取礼包样式
   * @param {Object} [node] 父节点
   */
  setRcvedGifts({
    node = document,
  } = {}) {
    for (let g of this.gifts) {
      g.isRcved({
        node,
      })
    }
  }

  /**
   * 签到
   */
  async signIn() {
    let ret

    try {
      ret = await memSignIn()
    } catch (e) {
      if (e && e.message) {
        utils.log.e(e.message)
      }

      error()
    }

    if (ret && ret.isOk() && ret.data) {
      if (ret.data) {
        this.keepSignIn = Number.parseInt(ret.data)
        return true
      }
    } else {
      error(ret && ret.msg ? ret.msg : undefined)
    }

    function error(txt = constants.tips.user.sign.fail) {
      throw new Error(txt)
    }
  }

  /**
   * 签到查询
   */
  async querySignIn({
    month,
  } = {}) {
    let ret

    try {
      ret = await memSignInQuery()
    } catch (e) {
      if (e && e.message) {
        utils.log.e(e.message)
      }

      error()
    }

    if (ret && ret.isOk() && ret.data) {
      for (let d of ret.data) {
        if (typeof d.signindate === 'string') {
          this.signInDate.push(Number.parseInt(d.signindate.substr(-2)))
        }
      }
    } else {
      error(ret && ret.msg ? ret.msg : undefined)
    }

    function error(txt = constants.tips.user.sign.fail) {
      throw new Error(txt)
    }
  }
}