import { log } from 'utils/log'

/**
 * 检测被动事件兼容性
 */
export function passiveEvtSupported () {
  let passiveSupported = false
  let options

  try {
    options = Object.defineProperty({}, 'passive', {
      get: () => {
        passiveSupported = true
        return false
      }
    })
  } catch (e) {
    if (e.message) {
      log.e(e.message)
    }
  }

  // 经测试，若第三个参数为对象，增加监听后浏览器立即检测该对象的属性。
  window.addEventListener('_test', null, options)

  return passiveSupported
}
