import { ajax, Result } from 'js/api/index'

/**
 * 注册会员
 * @param {String} from 请求来源。 1 Android、2 移动端、3 iOS、 4 PC
 * @param {String} email 电子邮箱
 * @param {String} imgCode 图形验证码
 * @param {String} mobile 手机号码
 * @param {String} password 密码
 * @param {String} siteId
 * @param {String} smscode 短信验证码
 * @param {Number} type 请求类型。0 验证码；1 注册请求
 */
export async function register (options = {}) {
  let {
    from = 2,
    email,
    imgCode = '',
    phoneNum = '',
    password = '',
    siteId = siteid ? siteid : '124',
    smsCode = '',
    type = 1
  } = options

  let data = {
    authcode: imgCode,
    email,
    from,
    mobile: phoneNum,
    password,
    siteid: siteId,
    smscode: smsCode,
    smstemp: type
  }

  if (email) {
    data.email = email
  }

  let ret = await ajax.post({
    url: 'api/app/member_regist',
    data,
  })

  return new Result({
    msg: ret._Message,
    status: ret._Status,
  })
}
