import config from 'config/config'

/**
 * 控制台输出
 */
export const log = {
  d: (...args) => {
    if (config.log.charAt(3) === '1') {
      /* eslint-disable no-console */
      console.debug(...args)
      /* eslint-enable no-console */
    }
  },
  e: (...args) => {
    if (config.log.charAt(0) === '1') {
      /* eslint-disable no-console */
      console.error(...args)
      /* eslint-enable no-console */
    }
  },
  i: (...args) => {
    if (config.log.charAt(2) === '1') {
      /* eslint-disable no-console */
      console.info(...args)
      /* eslint-enable no-console */
    }
  },
  w: (...args) => {
    if (config.log.charAt(1) === '1') {
      /* eslint-disable no-console */
      console.warn(...args)
      /* eslint-enable no-console */
    }
  },
}