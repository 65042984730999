// 常量
import * as constants from 'constants'
// 工具方法
import * as utils from 'utils'

/**
 * IOS、安卓平台显示、移除元素
 * @param {Object} android android 元素
 * @param {Object} ios ios 元素
 * @param {Number} platForm 平台。android / ios
 * @return {Boolean} 成功 true; 失败 false
 */
export function el(options = {}) {
  options = Object.assign({}, options)

  if (!(initArgs(options) && ivArgs(options))) {
    return false
  }

  start(options)

  return true
}

/**
 * 参数初始化
 */
function initArgs(options = {}) {
  if (options.android) {
    if (typeof options.android.bEl === 'string') {
      let o = document.querySelectorAll(options.android.bEl)

      if (o.length === 0) {
        return error()
      }

      options.android.bEl = o
    }

    if (typeof options.android.flexEl === 'string') {
      let o = document.querySelectorAll(options.android.flexEl)

      if (o.length === 0) {
        return error()
      }

      options.android.flexEl = o
    }

    if (typeof options.android.ilbEl === 'string') {
      let o = document.querySelectorAll(options.android.ilbEl)

      if (o.length === 0) {
        return error()
      }

      options.android.ilbEl = o
    }
  }

  if (options.ios) {
    if (typeof options.ios.bEl === 'string') {
      let o = document.querySelectorAll(options.ios.bEl)

      if (o.length === 0) {
        return error()
      }

      options.ios.bEl = o
    }

    if (typeof options.ios.flexEl === 'string') {
      let o = document.querySelectorAll(options.ios.flexEl)

      if (o.length === 0) {
        return error()
      }

      options.ios.flexEl = o
    }

    if (typeof options.ios.ilbEl === 'string') {
      let o = document.querySelectorAll(options.ios.ilbEl)

      if (o.length === 0) {
        return error()
      }

      options.ios.ilbEl = o
    }
  }

  return true

  function error() {
    utils.log.e(constants.tips.console.domElNotFound)
    return false
  }
}

/**
 * 参数校验
 */
function ivArgs(options = {}) {
  let {
    android,
    ios,
    platform,
  } = options

  if (android) {
    if (android.bEl && !(android.bEl instanceof NodeList)) {
      // 块级元素
      return error()
    }

    if (android.flexEl && !(android.flexEl instanceof NodeList)) {
      // 块级元素
      return error()
    }

    if (android.ilbEl && !(android.ilbEl instanceof NodeList)) {
      // 行内替换元素
      return error()
    }
  }

  if (ios) {
    if (ios.bEl && !(ios.bEl instanceof NodeList)) {
      // 块级元素
      return error()
    }

    if (ios.flexEl && !(ios.flexEl instanceof NodeList)) {
      // 块级元素
      return error()
    }

    if (ios.ilbEl && !(ios.ilbEl instanceof NodeList)) {
      // 行内替换元素
      return error()
    }
  }

  let rs
  for (let o of Object.values(constants.ua.platform)) {
    if (platform === o) {
      rs = true
    }
  }
  if (!rs) {
    return error()
  }

  return true

  function error() {
    utils.log.e(`[showEl][ivArgs] ${constants.tips.console.ivArgs}`)
    return false
  }
}

function start(options = {}) {
  let {
    android,
    ios,
    platform,
  } = options

  if (platform === constants.ua.platform.ios) {
    // ios
    if (android) {
      remove(android.bEl)
      remove(android.flexEl)
      remove(android.ilbEl)
    }

    if (ios) {
      show({
        arr: ios.bEl,
        style: 'block',
      })
      show({
        arr: ios.flexEl,
        style: 'flex',
      })
      show({
        arr: ios.ilbEl,
        style: 'inline-block',
      })
    }
  } else {
    // 安卓
    if (ios) {
      remove(ios.bEl)
      remove(ios.flexEl)
      remove(ios.ilbEl)
    }
    if (android) {
      show({
        arr: android.bEl,
        style: 'block',
      })
      show({
        arr: android.flexEl,
        style: 'flex',
      })
      show({
        arr: android.ilbEl,
        style: 'inline-block',
      })
    }
  }

  function show(options = {}) {
    let {
      arr = [],
        style = 'block',
    } = options

    for (let o of arr) {
      o.style.display = style
    }
  }

  function remove(arr = []) {
    for (let o of arr) {
      if (o.parentNode) {
        o.parentNode.removeChild(o)
      }
    }
  }
}
