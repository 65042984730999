// API
import {
  isLogin as isLoginApi
} from 'api'
// 常量
import * as constants from 'constants'
// 实体
import {
  UserEntity,
} from 'js/entity/index'
// 工具方法
import * as utils from 'utils'

import { dataBus } from 'js/common/dataBus/index'

/**
 * 用户登录状态检查
 * @return {Boolean} true 已登录；false 未登录
 */
export async function isLogin() {
  let ret
  try {
    ret = await isLoginApi()
  } catch (e) {
    // 查询登陆状态失败，不抛出错误，视为未登录，继续执行代码
    if (e && e.message) {
      utils.log.e(e.message)
    }

    error()
  }

  if (ret && ret.isOk()) {
    let user = new UserEntity({
      avatarUrl: ret.data.logofile ? ret.data.logofile : '',
      gender: ret.data.gender ? ret.data.gender : '',
      keepSignIn: ret.data.keepsigninday ? ret.data.keepsigninday : 0,
      phoneNum: ret.data.mobile ? ret.data.mobile : '',
      nickName: ret.data.nickname ? ret.data.nickname : '',
      score: ret.data.balance ? ret.data.balance : '',
      signature: ret.data.signature ? ret.data.signature : constants.tips.user.signature.default,
      userName: ret.data.username ? ret.data.username : '',
    })
    utils.log.w('判断是否有user' + user)
    user.logined()
    dataBus.get({
      key: 'entities',
    }).set('user', user)

    return true
  } else if (ret && !ret.isOk()) {
    // 与其它接口不同，status -1 未登录，非查询失败
    return false
  } else {
    error()
  }

  function error () {
    throw new Error(constants.tips.console.user.lgStatus)
  }
}
