import { init } from './init'
import { rmEmptyTab } from './rmEmptyTab'

/**
 * 块导航标签初始化
 */
export const tab = {
  init,
  rmEmptyTab,
}
