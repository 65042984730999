// 常量
import * as constants from 'constants'
// 工具方法
import * as utils from 'utils'

/**
 * 移除 404 图片所在的 swiper-slide
 * @param {Object | String} els img 元素
 */
export function rmBrokenImgs({
  els
} = {}) {
  if (typeof els === 'string') {
    els = document.querySelectorAll(els)

    if (els.length === 0) {
      utils.log.e(constants.tips.console.domElNotFound)
      return false
    }
  }

  if (!(els instanceof NodeList)) {
    return error()
  }

  for (let el of els) {
    if (!(el instanceof HTMLImageElement)) {
      return error()
    }
  }

  bindEvts({
    els,
  })

  function error() {
    utils.log.e(constants.tips.console.ivArgs)
    return false
  }
}

/**
 * 事件初始化
 */
function bindEvts({
  els
}) {
  for (let el of els) {
    if (typeof el.complete !== 'undefined' && el.complete === true) {
      // 图片加载结束
      if (!isSuccess(el)) {
        rmParentEl(el)
      }
      continue
    }

    el.addEventListener('error', e => rmParentEl(e.target), false)
  }

  /**
   * 结束加载的图片，是否成功
   */
  function isSuccess(el) {
    if (typeof el.naturalWidth !== 'undefined') {
      // 支持 naturalWidth
      return  el.naturalWidth && el.naturalHeight ? true : false
    }

    let styles = window.getComputedStyle(el, null)

    return Number.parseInt(styles.getPropertyValue('width')) && Number.parseInt(styles.getPropertyValue('height')) ? true : false
  }
}

/**
 * 移除父元素
 */
function rmParentEl(el) {
  let p = el

  while (!p.classList.contains('swiper-slide')) {
    if (p.nodeName.toLowerCase() === 'body' || p.classList.contains('swiper-container')) {
      return
    }

    p = p.parentNode
  }

  p.parentNode.removeChild(p)
}