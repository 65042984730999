// 常量
import * as constants from 'constants'
// 工具方法
import * as utils from 'utils'

/**
 * 返回上级页面，若无上级，返回首页
 * @param {Object | String} el 目标节点
 * @param {String} defaultUrl 无历史记录或上一页面非本站时跳转到默认 url
 */
export function back(options = {}) {
  options = Object.assign({}, options)

  if (!(initArgs(options) && ivArgs(options))) {
    return false
  }

  start(options)

  return true
}

/**
 * 参数初始化
 */
function initArgs(options = {}) {
  let {
    defaultUrl = '/top',
  } = options

  options.defaultUrl = defaultUrl

  if (typeof options.el === 'string') {
    let o = document.querySelector(options.el)

    if (!o) {
      return error()
    }

    options.el = o
  }

  return true

  function error() {
    utils.log.e(constants.tips.console.domElNotFound)
    return false
  }
}

/**
 * 参数校验
 */
function ivArgs(options = {}) {
  let {
    defaultUrl,
    el,
  } = options

  if (typeof defaultUrl !== 'string') {
    return error()
  }

  if (!(el instanceof HTMLElement || el instanceof SVGElement)) {
    return error()
  }

  return true

  function error() {
    utils.log.e(constants.tips.console.ivArgs)
    return false
  }
}

function start(options = {}) {
  let {
    defaultUrl,
    el,
  } = options

  el.addEventListener('click', handler, false)

  function handler() {
    if (typeof document.referrer !== 'undefined') {
      // 浏览器支持 referrer policy
      if (document.referrer === '') {
        // 不存在页面引用来源
        return window.location.href = defaultUrl
      }

      let parser = new utils.UrlParser()
      if (document.referrer.indexOf(parser.domainName) === -1) {
        // 当前页面导航自非站内页面
        return window.location.href = defaultUrl
      }
      // 当前页面导航自站内页面
    }

    if (!window.history) {
      // 浏览器不支持历史记录 API
      return window.location.href = defaultUrl
    }

    // 新建标签页返回默认 URL，否则返回上级页面
    return history.length < 3 ? window.location.href = defaultUrl : history.go(-1)
  }
}
