// 常量
import * as constants from 'constants'

import { log } from 'utils/index'

/**
 * 四舍五入
 * @param {Number} n 数值
 * @return {Number || NaN} 整数近似值
 */
export function approximate (n) {
  if (typeof n !== 'number') {
    log.e(constants.tips.console.ivArgs)
    return NaN
  }

  if (n <= 0 && (Math.ceil(n) - n >= 0.5)) {
    n = Math.floor(n)
  } else {
    n = Math.round(n)
  }

  return n
}
