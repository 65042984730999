import { ajax, Result } from 'js/api/index'

/**
 * 领取礼包
 * @param {Number} giftId 礼包ID
 */
export async function getGift ({ giftId } = {}) {
  let ret = await ajax.post({
    url: 'api/appgift/receive',
    data: {
      giftid: giftId
    }
  })

  let rs = new Result({
    data: typeof ret.giftcode === 'undefined' ? null : { giftCode: ret.giftcode },
    msg: ret._Message,
    status: ret._Status,
  })

  return rs
}