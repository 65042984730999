// 配置文件
import config from 'config/config'
// 工具方法
import * as utils from 'utils'

export default async function mock() {
  if (!config.isMock) {
    return
  }

  try {
    // let module = await import(`mockjs`)
    // let data = module.mock({
    //   'list|1-10': [{
    //     'id|+1': 1
    //   }]
    // })
  } catch (e) {
    if (e && e.message) {
      utils.log.e(e.message)
    }
  }
}
