// 常量
import * as constants from 'constants'
// 工具方法
import * as utils from 'utils'

/**
 * 移除
 */
export function rm () {
  let o = document.querySelector('.yc-mask-loading')
  if (!o) {
    utils.log.e(constants.tips.console.domElNotFound)
    return false
  }

  if (o.parentNode) {
    o.parentNode.removeChild(o)
  }
}
