/**
 * undefined、null 判断，任一数据未定义即返回真值。
 * @param {Object | Number | String | Array} 待检测数据
 * @return {Boolean} true 未定义; false 已定义
 */
export function isUndefined (...o) {
  for (let obj of o) {
    if (typeof obj === 'undefined' || obj === null) {
      return true
    }
  }

  return false
}