/**
 * 取 cookie
 */
export function get (options = {}) {
  let cookies = document.cookie.split(';')
  
  for (let [i, c] of cookies.entries()) {
    cookies[i] = c.trim().split('=')
  }

  return new Map(cookies)
}
