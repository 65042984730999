// 常量
import * as constants from 'constants'

import { log } from 'utils/log'

/**
 * 手机号码格式化
 * @param {Number} digit 填充位数
 * @param {String} num 手机号码
 * @param {String} sign 填充符号
 * @param {Number} startPos 起始填充位置。从 0 开始计数
 */
export function phonenum (options = {}) {
  let {
    digit = 1,
    num = '',
    sign = '*',
    startPos = 0
  } = options

  if (typeof digit !== 'number' && typeof digit !== 'string' && typeof sign !== 'string' && typeof startPos !== 'number') {
    return error()
  }

  if (digit <= 0 || startPos + digit >= num.length) {
    return error()
  }

  let arr = num.split('')
  arr.splice(startPos, digit, '*'.repeat(digit))
  arr = arr.join('')

  return arr

  function error () {
    log.e(constants.tips.console.ivArgs)
    return false
  }
}