/**
 * 返回设备类型
 * @return {Object} 设备类型
 */
export function devType() {
  let ua = window.navigator.userAgent.toLowerCase() // 用户代理
  let browser = getBrowser(ua)

  return {
    ...browser,
    language: getLanguage(ua),
    versions: getVersions({
      browser,
      ua,
    }),
  }
}

/**
 * 判断
 * @param {String} ua 浏览器标识
 */
function getLanguage (ua) {
  return (window.navigator.browserLanguage || window.navigator.language).toLowerCase()
}

/**
 * 判断浏览器类型
 * @param {String} ua 浏览器标识
 */
function getBrowser (ua) {
  let android = ua.indexOf('android') > -1 || ua.indexOf('Linux') > -1 // android终端
  let gecko = ua.indexOf('gecko') > -1 && ua.indexOf('KHTML') == -1 //火狐内核
  let ios = !!ua.match(/\(i[^;]+;( u;)? cpu.+mac os x/) //ios终端
  let iPad = ua.indexOf('ipad') > -1 //iPad
  let iPhone = android || ios || ua.indexOf('iphone') > -1 || ua.indexOf('mac') > -1 //是否为iPhone或者QQHD浏览器
  let mobileBaiDu = ua.indexOf('baiduboxapp') > -1 // 手机百度
  let presto = ua.indexOf('presto') > -1 //opera内核
  let qqBrowser = ua.indexOf('mqqbrowser') > -1 // QQ浏览器
  let safari = false // safari 浏览器
  let trident = ua.indexOf('trident') > -1 // IE trident 内核
  let ucBrowser = ua.indexOf('ucbrowser') > -1 // UC浏览器
  let webKit = ua.indexOf('applewebkit') > -1 // 苹果、谷歌内核
  let wechatBrowser = ua.indexOf('micromessenger') > -1 // 微信

  let mobile = android || ios // 移动终端

  if (!(mobileBaiDu || qqBrowser || ucBrowser || wechatBrowser)) {
    // safari 的版本号在 version/ 后，chrome 的版本号在 Chrome/ 后，可据此过滤出 safari
    safari = ua.match(/version\/[\d.]+.*safari/) ? true : false
  }

  return {
    android,
    gecko,
    ios,
    iPad,
    iPhone,
    mobile,
    mobileBaiDu,
    presto,
    safari,
    trident,
    webKit,
    wechatBrowser
  }
}

/**
 * 判断浏览器类型
 * @param {Object} browser 浏览器类型
 * @param {String} ua 浏览器标识
 */
function getVersions (options = {}) {
  let {
    browser,
    ua,
  } = options
  let versions = {}

  if (browser.mobileBaiDu) {
    // 手机百度版本号
    let v =  ua.match(/baiduboxapp\/([\d.]+)/)
    versions.mobileBaiDu = v.length > 1 ? v[1] : undefined
  }

  return versions
}
