import { ajax, Result } from 'js/api/index'

/**
 * 重置密码
 * @param {String} mobile 手机号
 * @param {String} password 密码
 * @param {String} authCode 短信验证码
 */
export async function resetPwd (options = {}) {
  let {
    authCode = '',
    mobile = '',
    password = '',
  } = options

  let ret = await ajax.post({
    url: 'api/app/sdk_resetpassword',
    data: {
      authcode: authCode,
      mobile,
      password,
    },
  })

  return new Result({
    msg: ret._Message,
    status: ret._Status,
  })
}
