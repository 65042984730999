// 常量
import * as constants from 'constants'
// 工具方法
import * as utils from 'utils'

/**
 * 显示 / 隐藏 更多文本
 * @param {Object} container 文本容器。
 * @param {Object} dpBtn “显示更多”按钮。
 * @param {Object} hBtn “隐藏更多”按钮。
 * @param {Number} limitValue 临界高度值。以 行数 / px / rem 为单位。
 * @param {Boolean} isJump 收缩内容时是否跳跃。
 * @param {String} mode 模式。 'pixel' 像素临界高度；'line' 临界行数；'rem' rem 高度
 * @return {Boolean} 成功 true; 失败 false。
 */
export function more(options = {}) {
  options = Object.assign({}, options)
  
  if (!(initArgs(options) && ivArgs(options))) {
    return false
  }

  start(options)
}

/**
 * 参数初始化
 */
function initArgs(options = {}) {
  let {
    isJump = true,
    mode = 'pixel',
  } = options

  options.isJump = isJump
  options.mode = mode

  if (typeof options.container === 'string') {
    let o = document.querySelector(options.container)

    if (!o) {
      return error()
    }

    options.container = o
  }

  if (typeof options.dpBtn === 'string') {
    let o = document.querySelector(options.dpBtn)

    if (!o) {
      return error()
    }

    options.dpBtn = o
  }

  if (typeof options.hBtn === 'string') {
    let o = document.querySelector(options.hBtn)

    if (!o) {
      return error()
    }

    options.hBtn = o
  }

  return true

  function error () {
    utils.log.e(constants.tips.console.domElNotFound)
    return false
  }
}

/**
 * 参数校验
 */
function ivArgs(options = {}) {
  let {
    container,
    dpBtn,
    hBtn,
    mode,
  } = options

  if (!(container instanceof HTMLElement)) {
    return error()
  }

  if (!(dpBtn instanceof HTMLElement)) {
    return error()
  }

  if (!(hBtn instanceof HTMLElement)) {
    return error()
  }

  if (!['line', 'pixel', 'rem'].includes(mode)) {
    return error()
  }

  return true

  function error () {
    utils.log.e(constants.tips.console.ivArgs)
    return false
  }
}

/**
 * 获取容器高度上限
 * @return {Boolean | Number} true 容器高度未达到临界值；number 最大高度数值
 */
function getMaxHt (options = {}) {
  let {
    container,
    limitValue,
    mode,
  } = options

  let conHt = container.clientHeight // 容器高度

  switch (mode) {
    case 'line': {
      let lineHt = Number.parseFloat(window.getComputedStyle(container, null).getPropertyValue('line-height')) // 行高
      let line = utils.math.approximate(conHt / lineHt) // 行数

      if (line <= limitValue) {
        // 内容行数小于临界行数
        return false
      }

      return limitValue * lineHt
    }
    case 'pixel': {
      if (conHt <= limitValue) {
        // 内容高度小于临界高度
        return false
      }

      return limitValue
    }
    case 'rem': {
      if (conHt <= limitValue * window.getComputedStyle(document.querySelector('html'), null).getPropertyValue('font-size')) {
        // 内容高度小于临界高度
        return false
      }

      return limitValue
    }
  }
}

/**
 * 设置容器高度上限
 */
function setMaxHT(options = {}) {
  let {
    container,
    ht,
    unit,
  } = options

  container.style.height = `${ht}${unit}`
}

/**
 * 显示按钮
 */
function showBtn(options = {}) {
  let {
    btnType,
    dpBtn,
    hBtn,
  } = options

  dpBtn.style.display = btnType === 'dp' ? 'block' : 'none'
  hBtn.style.display = btnType === 'hide' ? 'block' : 'none'
}

function start (options = {}) {
  let {
    container,
    dpBtn,
    hBtn,
    isJump,
    mode,
  } = options
  let maxHt = getMaxHt(options)
  let unit = mode === 'rem' ? 'rem' : 'px'

  if (!maxHt) {
    return true
  }

  setMaxHT({
    container,
    ht: maxHt,
    unit,
  })

  showBtn({
    btnType: 'dp',
    dpBtn,
    hBtn,
  })

  dpBtn.addEventListener('click', dpBtnHandler, false)
  hBtn.addEventListener('click', hBtnHandler, false)

  return true

  function dpBtnHandler () {
    container.style.height = 'auto'
    showBtn({
      btnType: 'hide',
      dpBtn,
      hBtn,
    })
  }

  function hBtnHandler () {
    setMaxHT({
      container,
      ht: maxHt,
      unit,
    })
    showBtn({
      btnType: 'dp',
      dpBtn,
      hBtn,
    })

    if (isJump) {
      document.documentElement.scrollTop = document.body.scrollTop = dpBtn.offsetTop - (window.screen.availHeight / 2)
    }
  }
}
