// 常量
import * as constants from 'constants'
// 工具方法
import * as utils from 'utils'

const chainStatus = Symbol('chainStatus')
const evtsQue = Symbol('evtsQue')
const subBuffer = Symbol('subscribeBuffer')
let cancelMsg = '[funChain] canceled' // 取消方法链返回的错误提示，用于在甄别是取消还是正常流程产生的错误

// 私有方法
const emitEvt = Symbol('emit event')
const removePrevBus = Symbol('remove prev events bus')
const setEvtsBus = Symbol('set events bus')
const subscribe = Symbol('subscribe event')

/**
 * 异步方法链(未测试是否支持同步）。支持单一事件多个回调。
 */
export class FunChain {
  constructor() {
    this[chainStatus] = constants.fChainStatus.get('idle') // 方法链状态
    this[evtsQue] = new Map() // 事件回调队列
    this[subBuffer] = resetSubBuffer() // 事件回调队列缓冲区。当订阅对象集合未初始化时使用。
  }

  /**
   * 无论成功或失败均执行
   * @param {Function} callback 
   * @return {Object} this 指针
   */
  always(callback) {
    this[subscribe]({
      evtName: 'always',
      callback,
    })

    return this
  }

  /**
   * 成功且所有行动已完成后(如所有数据加载完成)执行
   * @param {Function} callback 
   */
  completed(callback) {
    this[subscribe]({
      evtName: 'completed',
      callback,
    })

    return this
  }

  /**
   * 成功后执行
   * @param {Function} callback 
   */
  done(callback) {
    this[subscribe]({
      evtName: 'done',
      callback,
    })

    return this
  }

  /**
   * 失败后执行
   * @param {Function} callback 
   */
  failed(callback) {
    this[subscribe]({
      evtName: 'failed',
      callback,
    })

    return this
  }

  /** 
   * 获取方法链状态
   * @param {Number} 方法链状态值
   */
  getChainStatus() {
    return this[chainStatus]
  }

  /**
   * 比对周期变更。 0 记录; 1 比较。
   * @return {Object | Boolean} 记录模式下返回存储的周期标识；比较模式下，返回是否为当前周期。
   */
  getPeriodFlag() {
    return this[evtsQue].keys().next().value
  }

  /**
   * 状态名
   * @param {String} status
   */
  setChainStatus(status = '') {
    this[chainStatus] = constants.fChainStatus.get(status)
  }

  /**
   * 启动异步方法链
   * @param {Function} fun 待执行方法
   * @param {Object} args 待执行方法参数
   * @return {Object} this 指针
   */
  startChain({
    fun,
    args
  }) {
    let obj = createStopPromise()
    this[setEvtsBus](obj)
    // 重置方法链状态
    this.setChainStatus('idle')
    // 清空遗留的事件订阅队列，保留当前事件订阅队列
    this[removePrevBus](obj.p)

    Promise.race([fun(args), obj.p])
      .then(ret => {
        let status = this.getChainStatus()

        // 异步操作成功，但其后执行失败
        if (status <= constants.fChainStatus.get('failed')) {
          this[emitEvt]({
            evtName: 'failed',
            param: ret,
          })
        }

        // 执行成功
        if (status >= constants.fChainStatus.get('done')) {
          this[emitEvt]({
            evtName: 'done',
            param: ret,
          })
        }

        // 执行完毕
        if (status >= constants.fChainStatus.get('completed')) {
          this[emitEvt]({
            evtName: 'completed',
            param: ret,
          })
        }

        // 默认回调
        this[emitEvt]({
          evtName: 'always',
          param: ret,
        })

        // 中止 promise fulfilled
        this[evtsQue].get(obj.p).resolveChain()
      })
      .catch(e => {
        // 异步操作执行失败
        if (this.getChainStatus() <= constants.fChainStatus.get('failed')) {
          this[emitEvt]({
            evtName: 'failed',
          })
        }

        // 默认回调
        this[emitEvt]({
          evtName: 'always',
        })

        if (e.message) {
          if (e.message === cancelMsg) {
            // 操作被取消
            utils.log.d(e.message)
          } else {
            // 操作异常
            utils.log.e('-asda')
            utils.log.e(e.message)
            utils.log.e('-asda')
          }
        }
      })

    return this
  }

  /**
   * 发射事件
   * @param {String} evtName 事件名
   * @param {Object} param 参数
   */
  [emitEvt]({
    evtName = '',
    param,
  } = {}) {
    let obj = this[evtsQue].values().next().value

    /**
     * 执行事件回调并移除已执行方法。
     * notes: 回调方法中有可能对订阅对象的回调列表进行增减，导致事件发射队列长度增加，从而无限循环
     */
    while (obj[evtName].length > 0) {
      let f = obj[evtName].shift()

      typeof f === 'function' ? f(param) : utils.log.e(constants.tips.console.ivArgs)
    }
  }

  /**
   * 移除先前周期的事件订阅对象
   * @param {Object} p 当前周期的中止方法链 promise
   */
  [removePrevBus](p) {
    for (let [k, v] of this[evtsQue].entries()) {
      if (k !== p) {
        // 中止未完成的方法链
        v.cancelChain()
        // 移除上一周期的事件订阅对象
        this[evtsQue].delete(k)
      }
    }
  }

  /**
   * 事件回调队列增加当前事件订阅对象
   */
  [setEvtsBus](obj) {
    this[evtsQue].set(obj.p, {
      always: this[subBuffer].always,
      completed: this[subBuffer].completed,
      done: this[subBuffer].done,
      failed: this[subBuffer].failed,
      cancelChain: obj.cancelChain,
      resolveChain: obj.resolveChain,
    })

    // 清空订阅对象缓冲区
    this[subBuffer] = resetSubBuffer()
  }

  /**
   * 订阅事件，注册的回调将在下一周期执行后注销。
   * @param {String} evtName 事件名
   * @param {Function} callback 回调
   */
  [subscribe]({
    evtName = '',
    callback = () => {}
  } = {}) {
    // 增量形式添加回调
    this[subBuffer][evtName].push(ret => callback(ret))
  }
}

/**
 * 生成中止方法链用到的 promise
 */
function createStopPromise() {
  let cancelChain
  let resolveChain
  let p = new Promise((resolve, reject) => {
    cancelChain = () => {
      reject(new Error(cancelMsg))
    }
    resolveChain = resolve
  })

  return {
    cancelChain,
    p,
    resolveChain,
  }
}

/** 
 *  生成事件订阅缓冲区
 */
function resetSubBuffer() {
  return {
    always: [],
    completed: [],
    done: [],
    failed: [],
  }
}
