/**
 * 日期格式化 yyyy/mm/dd
 * @param {Object} date date 对象
 */
export function date (options = {}) {
  let {
    date,
    symbol = '/',
  } = options
  let y = date.getFullYear()
  let m = date.getMonth() + 1
  let d = date.getDate()

  if (m < 10) {
    m = '0' + m
  }
  if (d < 10 ) {
    d = '0' + d
  }

  return `${y}${symbol}${m}${symbol}${d}`
}