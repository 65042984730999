// jQuery
import $ from 'jquery'
// 工具方法
import * as utils from 'utils'

/**
 * 表格导航栏事件初始化
 */
export function navTable ({ tdPerRow = 5 } = {}) {
  let boxObj = $('.yui-nav-table-box').eq(0)

  navTableInit({
    tdPerRow
  })

  boxObj.on('click', 'td', function (evt) {
    if ($(this).hasClass('flag')) {
      // 点击箭头所在单元格
      let arrow = $(this).children('.icon')

      if (arrow.hasClass('yui-icon-arrow2-bluedown')) {
        boxObj.css({ overflow: 'visible' })
      } else {
        boxObj.css({ overflow: 'hidden' })
      }

      arrow.toggleClass('yui-icon-arrow2-bluedown')
      arrow.toggleClass('yui-icon-arrow2-blueup')
    }
  })

  /**
   * 选中栏置顶
   */
  function navTableInit ({ tdPerRow }) {
    let arrow = boxObj.find('.flag')
    let trs = boxObj.find('tbody tr')
    let selectedTr = boxObj.find('.selected').eq(0).parent('tr')
    let diff = selectedTr.eq(0).children().length - tdPerRow // 选中行 td 数与行应有 td 数之差

    if (diff < 0) {
      // tr 内部 td 数量不足
      let index = Array.from(trs).findIndex(ele => {
        return ele === selectedTr[0]
      }) // 选中行索引

      if (index >= 1) {
        let beforeTds = Array.from(trs.eq(index - 1).children()) // 数量不足选中行的前一行所有单元格

        while (diff < 0) {
          selectedTr.eq(0).prepend(beforeTds.pop())
          diff++
        }
        utils.log.d('[navTable] 选中行单元格数量不足, 已补充。')
      } else {
        utils.log.d('[navTable] 选中行单元格数量不足, 且无法补充。')
      }
    }

    selectedTr.eq(0).append(arrow.eq(0)).prependTo(boxObj.find('tbody').eq(0))
  }
}