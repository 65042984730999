/**
 * 增加 cookie
 */
export function set (options = {}) {
  let {
    key = '',
    value = '',
  } = options

  document.cookie = `${key}=${value}`

  return true
}
