export default {
  /**
   * 用户头像
   */
  avatar: {
    maxSize: 4, // 大小上限，单位 MB
  },
  /**
   * 评论
   */
  cmmt: {
    minLen: 5, // 最小长度
  },
  /**
   * 控制台错误日志输出。
   * 0 禁用; 1 启用。
   * 第一位表示 error; 第二位表示 warn; 第三位表示 info; 第四位表示 debug。
   */
  log: '1100',
  /**
   * 启用模拟数据拦截 ajax 请求
   */
  isMock: false,
  /**
   * 密码
   */
  password: {
    maxLen: 16, // 最大位数
    minLen: 6, //最小位数
  },
  /**
   * 验证码重发时间(s)
   */
  vcodeTime: 60,
}
