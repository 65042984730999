import { ajax, Result } from 'js/api/index'

/**
 * 用户签到查询（默认当月）
 * @param {Number} [month] 查询月份
 */
export async function memSignInQuery (options = {}) {
  let date = new Date()
  let y = date.getFullYear()
  let m = date.getMonth() + 1
  m = m < 10 ? `0${m}` : m

  let {
    month = `${y}${m}`
  } = options

  let ret = await ajax.post({
    url: 'api/app/sdksignindata',
    data: {
      month,
    }
  })

  return new Result({
    data: ret._RESULT,
    msg: ret._Message,
    status: ret._Status,
  })
}

/**
 * 用户签到
 */
export async function memSignIn () {
  let ret = await ajax.post({
    url: 'api/app/sdksignin'
  })

  return new Result({
    data: ret.signin_days,
    msg: ret._Message,
    status: ret._Status,
  })
}