// jQuery
import $ from 'jquery'
// 常量
import * as constants from 'constants'
// 工具方法
import * as utils from 'utils'

/**
 * 将消息加入展示队列
 * @param {String} className 类名(不含.)
 * @param {String} iconName svg 图标名
 * @param {Number} timeout 显示时长
 * @param {Number} timeoutCallback 计时结束回调
 * @param {String} txt 文本
 */
export function show(options = {}) {
  if (!(initArgs(options) && ivArgs(options))) {
    return false
  }

  start(options)
}

/**
 * 参数初始化
 */
function initArgs (options = {}) {
  let {
    className = '',
    iconName = '#icon-msg-info0',
    txt = '',
    timeout = 2,
    timeoutCallback = () => {},
  } = options

  options.className = className
  options.iconName = iconName
  options.txt = txt
  options.timeout = timeout
  options.timeoutCallback = timeoutCallback

  return true
}

/**
 * 参数校验
 */
function ivArgs (options = {}) {
  let {
    className,
    iconName,
    txt,
    timeout,
    timeoutCallback,
  } = options

  if (typeof className !== 'string' || typeof iconName !== 'string' || typeof txt !== 'string') {
    return error()
  }

  if (typeof timeout !== 'number') {
    return error()
  }

  if (typeof timeoutCallback !== 'function') {
    return error()
  }

  return true

  function error () {
    utils.log.e(constants.tips.console.ivArgs)
    return false
  }
}

function start (options = {}) {
  let {
    className,
    iconName,
    txt,
    timeout,
    timeoutCallback,
  } = options

  let node = $(`
    <div class="y-msg0 y-msg0-top ${className}">
      <svg class="icon">
        <use xlink:href="${iconName}" href="${iconName}"></use>
      </svg>
      <span>${txt}</span>
    </div>
  `)[0]

  document.body.appendChild(node)

  setTimeout(() => {
    if (node.parentNode) {
      node.parentNode.removeChild(node)
    }

    try {
      timeoutCallback()
    } catch (e) {
      if (e && e.message) {
        utils.log.e(e.message)
      }
    }
  }, 1000 * timeout)
}

/**
 * 错误
 */
export function error (options = {}) {
  let {
    className = '',
    iconName = '#icon-msg-cross1',
  } = options

  className += ' msg-error'
  options.className = className
  options.iconName = iconName

  show({
    ...options
  })
}

/**
 * 信息
 */
export function info (options = {}) {
  let {
    className = '',
    iconName = '#icon-msg-info0',
  } = options

  className += ' msg-info'
  options.className = className
  options.iconName = iconName

  show({
    ...options
  })
}

/**
 * 成功
 */
export function success (options = {}) {
  let {
    className = '',
    iconName = '#icon-msg-tick0',
  } = options

  className += ' msg-success'
  options.className = className
  options.iconName = iconName

  show({
    ...options
  })
}

/**
 * 警告
 */
export function warning (options = {}) {
  let {
    className = '',
    iconName = '#icon-msg-warning0',
  } = options

  className += ' msg-warning'
  options.className = className
  options.iconName = iconName

  show({
    ...options
  })
}
