import { get } from './get'
import { set } from './set'

/**
 * cookie 操作集
 */
export const cookie = {
  get,
  set,
}
