// axios
import axios from 'axios'
// qs
import qs from 'qs'
// 工具方法
import * as utils from 'js/utils/index'
// 模拟数据
import mock from 'js/api/mock/index'

async function getMethod({
  url = '',
  data = {},
  options = {}
}) {
  utils.log.i(`[ajax][get] url <- `, url, `, data <- `, data)
  mock({
    url,
    data,
  })

  let ret
  try {
    ret = await axios({
      baseURL: `${process.env.NODE_ENV === 'production' ? prefix : SERVER_URL}`,
      params: data,
      method: 'get',
      url: `${process.env.NODE_ENV === 'production' ? prefix : SERVER_URL}${url}`,
      withCredentials: true,
      ...options
    })
  } catch (e) {
    if (e.message) {
      utils.log.e(e.message)
    }

    throw new Error(`[ajax][get] failed.`)
  }

  ret = ret.data

  return ret
}

/**
 * post 请求
 */
async function postMethod({
  url = '',
  data = {},
  options = {}
}) {
  utils.log.i(`[ajax][post] url <- `, url, `, data <- `, data)
  mock({
    url,
    data,
  })

  let ret
  try {
    ret = await axios({
      baseURL: `${process.env.NODE_ENV === 'production' ? prefix : SERVER_URL}`,
      data: data,
      method: 'post',
      transformRequest: [function (data, headers) {    
        return qs.stringify(data)
      }],
      url,
      withCredentials: true,
      ...options
    })

  } catch (e) {
    if (e.message) {
      utils.log.e(e.message)
    }

    throw new Error(`[ajax][post] failed.`)
  }

  ret = ret.data

  return ret
}

export const ajax = {
  get: getMethod,
  post: postMethod
}
