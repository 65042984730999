import { ajax, Result } from 'js/api/index'

/**
 * 提交评论
 * @param {String} cotent 评论内容
 * @param {Number} gameId 游戏ID
 * @param {String} relaType 关联内容类型
 */
export async function submitCmmt (options = {}) {
  let {
    content,
    gameId,
    relaType = 'App'
  } = options

  let ret = await ajax.post({
    url: 'api/simplecomment/submit',
    data: {
      acturalrelaid: gameId,
      extendrelaid: gameId,
      content,
      relaid: gameId,
      relatype: relaType,
    }
  })

  return new Result({
    data: ret.commentid,
    msg: ret._Message,
    status: ret._Status,
  })
}