/**
 * swiper 4.2.6-4.4.1 esm 文件未被polyfilled，导致IE11不兼容，暂时手动引入
 * BUG: 滚动屏幕同时滑动 slide ，控制台报错
 */
// Swiper
// import Swiper from 'swiper'
// 样式
export * from 'swiper/dist/css/swiper.min.css'
// 常量
import * as constants from 'constants'
// 工具方法
import * as utils from 'utils'

/**
 * 基于 swiper 插件的轮播及滑动效果。
 * @param {Object | String} el swiper容器的选择器
 * @param {Object} options swiper参数
 */
export function init (options = {}) {
  options = Object.assign({}, options)

  if (!(initArgs(options) && ivArgs(options))) {
    return false
  }

  return start(options)
}

/**
 * 参数初始化
 */
function initArgs(args = {}) {
  let {
    options = {
      autoplay: {
        delay: 6000, //可选选项，自动滑动
        disableOnInteraction: false,
      },
      initialSlide: 0,
      observer: true,
      observeParents: true,
      resistance : true,
      resistanceRatio: 0.85,
    },
    el = '.swiper-container'
  } = args

  args.options = options
  args.el = el

  return true
}

/**
 * 参数校验
 */
function ivArgs (options = {}) {
  let {
    el,
  } = options

  if (typeof el !== 'string' && !(el instanceof HTMLElement)) {
    return error()
  }

  return true

  function error () {
    utils.log.e(constants.tips.console.ivArgs)
    return false
  }
}

function start (opts = {}) {
  let {
    options,
    el,
  } = opts
  /* eslint-disable */
  return new Swiper(el, options)
  /* eslint-disable */
}
