// 常量
import * as constants from 'constants'
import {
  dataBus
} from 'js/common/dataBus/index'

/**
 * 解析 API 返回的 downloadLink 对象信息
 * @return {Boolean | Object} 下载链接、是否越狱
 */
export function parsePackInfo(rs) {
  if (!ivArgs(rs)) {
    return false
  }

  let browser = dataBus.get({
    key: 'browser'
  })
  let isBreakJail = constants.propertyValue.package.notBreakJail // 是否为越狱包
  let link = 'javascript:void(0)' // 下载链接

  for (let o of rs) {
    if (o.defaultpackage === constants.api.package.notDefault) {
      // 非默认下载包
      continue
    }

    if ((browser.ios && o.type === 'ipa') || (browser.android && o.type === 'apk')) {
      link = `${o.link}`

      if (browser.ios) {
        // ios
        isBreakJail = o.jailbreak === constants.api.package.breakJail ? '1' : '0'
      }

      break
    }
  }

  return {
    isBreakJail,
    link,
  }
}

/**
 * 参数校验
 */
function ivArgs(rs) {
  if (!(rs instanceof Array)) {
    return false
  }

  return true
}
