/**
 * 接口相关
 */
export const api = {
  succeed: 1, // 执行成功。
  failed: 0, // 执行失败。
  /**
   * 游戏包
   */
  package: {
    android: 'apk', // 安卓下载包。
    breakJail: 'Y', // 越狱包。
    default: 'Y', // 默认包。
    ios: 'ipa', // ios 下载包。
    notBreakJail: 'N', // 官方包。
    notDefault: 'N', // 非默认包。
  },
  /**
   * 好玩 / 不好玩。
   */
  likeType: {
    like: 1, // 好玩。
    unlike: 2, // 不好玩。
  },
  /**
   * 游戏截图
   */
  screenshot: {
    horz: '2', // 横图。
    vert: '1', // 纵图。
  },
  // 短信验证码
  sms: {
    // 请求来源
    from: {
      android: 1,
      mobile: 2,
      ios: 3,
      pc: 4,
    },
    type: {
      reg: 1, // 注册
      resetPwd: 2, // 改密
      setUserInfo: 3, // 修改个人信息
    },
  },
  // 用户
  user: {
    gender: {
      female: 'F', // 女
      male: 'M', // 男
    }, // 性别
  }
}
