// 常量
import * as constants from 'constants'

/**
 * 礼包实体
 * @param {String} code 激活码
 * @param {String} expTime 生效截至日期
 * @param {String} gameId 游戏ID
 * @param {String} id 礼包ID
 * @param {String} link 礼包详情URL
 * @param {String} imgUrl 图标URL
 * @param {String} name 礼包名
 * @param {String} startTime 生效起始日期
 * @param {String} thumbnailImgUrl 图标缩略图URL
 */
export class GiftEntity {
  constructor(options = {}) {
    let {
      code,
      expTime,
      gameId,
      id,
      link,
      imgUrl,
      name,
      startTime,
      thumbnailImgUrl,
    } = options

    this.code = code
    this.expTime = expTime
    this.gameId = gameId
    this.id = id
    this.link = link
    this.imgUrl = imgUrl
    this.name = name
    this.startTime = startTime
    this.thumbnailImgUrl = thumbnailImgUrl
  }

  /**
   * 是否为已领取礼包。已领取则改变按钮样式
   * @param {Object} [node] 父节点
   */
  isRcved({
    node = document,
  } = {}) {
    for (let el of node.querySelectorAll(`.yc-giftbtn0[${constants.propertyName.gift.id}="${this.id}"]`)) {
      el.classList.add('disabled')
      let txt = el.querySelector('span')
      if (txt) {
        txt.innerText = constants.tips.gift.received
      }
    }
  }
}