// jQuery
import $ from 'jquery'
// 常量
import * as constants from 'constants'
// 工具方法
import * as utils from 'utils'
import { Load } from './load'

const timesLeft = Symbol('timesLeft')

/**
 * 滚动加载
 */
export class ScrollLoad extends Load {
  constructor (options = {}) {
    let {
      times = 3,
    } = options

    super(options)
    this[timesLeft] = times // 滚动加载剩余次数
  }

  scrollListen (options = {}) {
    options = Object.assign({}, options)

    if (!(initArgs.bind(this)(options) && ivArgs(options))) {
      return false
    }

    let {
      gap, // 间距
      scrollListenEl, // 被监听元素
      scrollTrigger, // 触发事件元素
    } = options
    let isLoading = false // 是否已触发滚动加载
    let handler = () => {
      let status = this.getChainStatus()

      if (isLoading || status === constants.fChainStatus.get('completed')) {
        // 正在加载或自动加载已失败 || 所有数据加载完毕
        return
      }

      let moreTop = $(scrollListenEl).offset().top
      let btmToTop = Math.ceil($(window).height() + $(window).scrollTop()) // 视口底部到文档最顶部高度(视窗高度 + 滚动高度)

      if (btmToTop + gap < moreTop) {
        // 未滚动到底部
        return
      }

      if (status !== constants.fChainStatus.get('completed')) {
        scrollTrigger.click()
      }
      isLoading = true
    } // 滚动监听回调

    if (this[timesLeft] > 0) {
      window.addEventListener('scroll', utils.throttle({
        fn: handler,
      }), utils.compability.passiveEvtSupported() ? {
        passive: true
      } : false)

      let handler1 = () => {
        isLoading = false
        // 修改自动加载计数
        this[timesLeft]--
          if (this[timesLeft] <= 0) {
            // 取消滚动监听
            window.removeEventListener('scroll', handler)
          } else {
            this.done(handler1)
          }
      }
      this.done(handler1)
    }
  }
}

/**
 * 参数初始化
 */
function initArgs(options = {}) {
  let {
    gap = 0,
    timesLeft = 3,
  } = options
  options.gap = gap
  this.timesLeft = timesLeft

  if (typeof options.scrollListenEl === 'string') {
    let o = document.querySelector(options.scrollListenEl)

    if (!o) {
      return error()
    }

    options.scrollListenEl = o
  }

  if (typeof options.scrollTrigger === 'string') {
    let o = document.querySelector(options.scrollTrigger)

    if (!o) {
      if (!(options.scrollListenEl instanceof HTMLElement)) {
        return error()
      }

      options.scrollTrigger = options.scrollListenEl
    }

    options.scrollTrigger = o
  }

  return true

  function error() {
    utils.log.e(constants.tips.console.domElNotFound)
    return false
  }
}

/**
 * 参数校验
 */
function ivArgs(options = {}) {
  let {
    gap,
    scrollListenEl,
    scrollTrigger,
  } = options

  if (typeof gap !== 'number') {
    return error()
  }

  if (!(scrollListenEl instanceof HTMLElement)) {
    return error()
  }

  if (!(scrollTrigger instanceof HTMLElement)) {
    return error()
  }

  return true

  function error() {
    utils.log.e(constants.tips.console.ivArgs)
    return false
  }
}
