import { ajax, Result } from 'js/api/index'

/**
 * 排行榜数据
 * @param {Number} type 排序类型 {1:热门, 7:自定义}
 * @param {String} rankcode 自定义榜单编码
 * @param {String} clienttype 客户端类型 {apk:安卓, ipa:苹果} 
 * @param {String} cpFlag 客户端是否有价值
 * @param {String} publishtarget 发布目标 {pc:pc端, Html5:移动端}
 * @param {Number} pageindex 分页页码
 * @param {Number} pagesize 分页大小
 * @param {String} columns 返回字段
 */
export async function getRank ({ type = '', rankCode = '', clientType = '', cpFlag = 'Y', publishTarget  = 'Html5', pageIndex = '', pageSize = '', columns='id,logofile,thumnaillogofile,link,downloadlink,name,downloadnum,instruction,score,tags,proscore,filesize,operationstatus', host = ''} = {}) {
  let ret = await ajax.post({
    url: 'api/app/ranking',
    data: {
      clienttype: clientType,
      columns,
      cpflag: cpFlag,
      publishtarget: publishTarget,
      pageindex: pageIndex,
      pagesize: pageSize,
      rankcode: rankCode,
      type,
      host,
    }
  })

  let rs = new Result({
    data: ret._RESULT,
    msg: ret._Message,
    status: ret._Status,
    additionalData: typeof ret.total !== 'undefined' ? { total: ret.total } : null
  })

  return rs
}