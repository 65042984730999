// 工具方法
import * as utils from 'utils'

import {
  FunChain
} from 'js/common/decorators/index'

/**
 * 加载更多。该基类的子类必须含有getFragment方法。
 * @param {Object} scrollParam 滚动加载参数
 */
export class Load extends FunChain {
  constructor(options = {}) {
    super(options)

    let {
      amt = 0, // 加载计数
    } = options

    this.amt = amt
  }

  /**
   * 加载
   * @param {Function} api ajax 接口
   * @param {String} data ajax 请求数据
   */
  async getData(options = {}) {
    let {
      api,
      data,
    } = options

    return await api({
      ...data
    })
  }

  /**
   * 挂载
   * @param {Object} fragment DOM 文档碎片
   * @param {String} mountPos 挂载位置
   * @param {Object} ret 加载结果
   * @param {Object} target 挂载的目标节点
   */
  mount(options = {}) {
    let {
      data = {},
        fragment,
        mountPos = 'tail',
        ret,
        target,
    } = options
    let pageSize

    if (typeof data.pageSize !== 'undefined') {
      pageSize = data.pageSize
    }

    switch (mountPos) {
      case 'before':
        // 挂载到目标节点之前
        target.before(fragment)
        break
      case 'after':
        // 挂载到目标节点之后
        target.after(fragment)
        break
      case 'head':
        target.prepend(fragment)
        // 挂载到目标节点首部
        break
      case 'tail':
        target.appendChild(fragment)
        // 挂载到目标节点末尾
        break
      default:
        throw new Error('[load][mount] -> invalid argument mountPos')
    }

    if ((ret.data.length < pageSize || (ret.getAdditionalData('total') <= this.amt))) {
      // 所有数据加载完成
      this.setChainStatus('completed')
    } else {
      // 当前加载完成
      this.setChainStatus('done')
    }

    utils.log.i(`已加载所有数据，节点数量： ${this.amt}`)
  }

  /**
   * 启动加载方法链
   * @param {Object} options 参数
   */
  start(options) {
    return this.startChain({
      fun: async () => {
        let flag = this.getPeriodFlag() // 获取当前周期标识
        let ret
        try {
          ret = await this.getData(options)

          if (this.getPeriodFlag() !== flag) {
            // 周期已更迭
            return
          }

          if (ret && ret.isOk()) {
            this.mount({
              ret,
              fragment: this.getFragment({
                ret,
                ...options,
              }),
              ...options,
            })
          } else {
            // 加载失败
            throw new Error(ret && ret.msg ? ret.msg : `[load][start] -> failed.`)
          }
        } catch (e) {
          if (e && e.message) {
            utils.log.e(e.message)
          }

          this.setChainStatus('failed')
        }

        return ret
      },
      args: options,
    })
  }
}