// 常量
import * as constants from 'constants'
// 公共方法
import { nav } from 'js/common'
// 工具方法
import * as utils from 'utils'

export function init() {
  let el = document.querySelector('.yc-header1 .back')
  if (!el) {
    utils.log.e(constants.tips.console.domElNotFound)
    return false
  }

  nav.back({
    el,
  })
}