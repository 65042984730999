/**
 * 用户代理
 */
export const ua = {
  /**
   * 浏览器类型
   */
  browser: {
    gecko: 0,
    mobileBaiDu: 1,
    presto: 2,
    qqBrowser: 3,
    safari: 4,
    trident: 5,
    ucBrowser: 6,
    webKit: 7,
    wechatBrowser: 8,
  },
  /**
   * 标识当前平台类型
   */
  platform: {
    android: 0,
    ios: 1,
    pc: 2
  }
}
