import {
  mask,
} from 'js/common/ui/mask/index'
// 微信图片
import wechatTip from 'assets/images/ic-weixin.png'

/**
 * 微信浏览器下载遮罩
 */
export function wechatMask() {
  mask.create({
    bgColor: '0, 0, 0, 0.3',
    callback: () => {
      mask.hide({
        selector: '.yc-mask-wechat'
      })
    },
    selector: '.yc-mask-wechat',
    slot: [{
      name: 'top-box',
      content: `<img src="${wechatTip}">`
    }]
  })
}
