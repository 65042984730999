// jQuery
import $ from 'jquery'

let scrollTop = 0 // body 滚动条高度
let top // body top 属性

// 弹出层隐藏时调用
function whenMaskHide(obj) {
  // body 回归文档流
  $('body').removeClass('y-mask-open')
  // 恢复 body 位置
  document.body.style.top = top
  // 恢复 body 滚动条高度
  document.body.scrollTop = document.documentElement.scrollTop = scrollTop
}

// 弹出层出现前调用
function whenMaskShow(obj) {
  // 在弹出层显示之前，记录当前的滚动位置
  scrollTop = getScrollTop()
  // body脱离文档流
  $('body').addClass('y-mask-open')
  // 保存 body 的 top 属性
  top = document.body.style.top !== '' ? document.body.style.top : 'auto'
  // 把脱离文档流的body拉上去，否则页面会回到顶部
  document.body.style.top = `${-scrollTop}px`
}

function getScrollTop() {
  return document.body.scrollTop || document.documentElement.scrollTop
}

/**
 * 滚动穿透 BUG 修复
 */
export const scrollPenetration = {
  whenMaskHide,
  whenMaskShow,
}