import { ajax, Result } from 'js/api/index'

/**
 * 点赞
 * @param {Number} commentId 评论ID
 */
export async function cancelThumb ({ commentId } = {}) {
  let ret = await ajax.post({
    url: 'api/simplecomment/cancelsupport',
    data: {
      commentid: commentId
    }
  })

  return new Result ({
    msg: ret._Message,
    status: ret._Status
  })
}

/**
 * 取消点赞
 * @param {Number} commentId 评论ID
 */
export async function thumb ({ commentId } = {}) {
  let ret = await ajax.post({
    url: 'api/simplecomment/support',
    data: {
      commentid: commentId
    }
  })

  return new Result ({
    msg: ret._Message,
    status: ret._Status
  })
}